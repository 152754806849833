<template>
    <Modal ref="editFilter" title="Edit Filter" :instructions="modals.editFilterTitle" @success="updateFiltersFromModal">
        <div v-if="editFilterHasMultipleChoice">
            <CustomSelect style="display: inline-block;" :options="modals.lookupValues" displayField="displayName" v-model="modals.fieldValue" />
        </div>
        <div v-else>
            <CustomSelect :options="modals.filterTypes" displayField="displayName" v-model="modals.selectedType" />
            <input style="width: 230px;" v-model="modals.fieldValue" placeholder="value to filter">
        </div>
    </Modal>

    <CustomSelect data-cy="filters-sidebar-select-field" style="display: inline-block;" :options="fieldsThatCanBeFiltered()" :groupOrder="fieldGroupOrder" displayField="displayName" v-model="filterField" />
    <button v-if="matrixConfig" class="matrix-button" @click="toggleMatrix">
        <img src="../assets/img/matrix_icon.png"> 
    </button>
    <div v-if="filterField">
        <div v-if="showMultipleChoice">
            <CustomSelect data-cy="filters-sidebar-select-value" style="display: inline-block;" :options="lookupValues" displayField="displayName" v-model="filterDropdown.multipleChoiceSelected" />
            <PlusMinusButton style="margin-left: 10px;" title="Add filter" @click="addFilter" :add="true" :disabled="!canAddFilter"/>
        </div>
        <div v-else>
            <CustomSelect data-cy="filters-sidebar-select-filtertype" :options="filterTypes" displayField="displayName" v-model="filterDropdown.type" />
            <input data-cy="filters-sidebar-input" style="width: 230px;" v-model="filterDropdown.inputFieldValue" placeholder="value to filter">
            <PlusMinusButton style="margin-left: 10px;" title="Add filter" @click="addFilter" :add="true" :disabled="!canAddFilter" />
        </div>
    </div>
    <div v-for="(item, index) in filters" :key="index">
        <PlusMinusButton title="Delete filter" style="margin-right: 10px;" @click="deleteFilter(item)" :add="false" />
        {{ item.displayName }} {{ item.displayType }} {{ getFilterDisplayValue(item) }} 
        <span 
            class="material-icons edit-filter-button" 
            @click="launchEdit(item)"
            title="Edit Filter"
        >
            edit
        </span>
    </div>
    <CustomButton data-cy="clear-filters" v-if="filters.length" @click="clearFilters" buttonText="Clear Filters" />
</template>

<script>
import Modal from '@/components/modal.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import PlusMinusButton from '@/components/plusMinusButton.vue';
import { getValueFromObject, getGroupValue, getDisplayValue } from '@/functions/utils.js';

const allFilterTypes = [
    { displayName: '>', tabulatorFunction: '>' }, 
    { displayName: '<', tabulatorFunction: '<' },
    { displayName: 'contains', tabulatorFunction: 'like' }, 
    { displayName: 'is exactly', tabulatorFunction: '=' }
];

export default {
    props: {
        matrixConfig: Object
    },
    data() {
        return {
            filterField: '',
            filterDropdown: {
                type: '',
                value: '',
                multipleChoiceSelected: ''
            },
            filters: [],
            filterBeingEdited: {},
            editFilterHasMultipleChoice: false,
            modals: {
                editFilterTitle: '',
                fieldValue: '',
                filterTypes: [],
                selectedType: '',
                lookupValues: []
            }
        }
    },
    emits: ['filtersChanged', 'toggleMatrix'],
    watch: {
        filters: function () {
            this.$emit('filtersChanged', this.filters);
        }
    },
    components: {
        CustomSelect, CustomButton, PlusMinusButton, Modal
    },
    computed: {
        showMultipleChoice: function () {
            // decide if the filter options area should show a multiple choice
            if (!this.filterField) return false;
            const fieldType = this.config.fields.find(field => field.displayName == this.filterField).type;
            return (fieldType == 'category') || (fieldType == 'boolean')
        },
        lookupValues: function () {
            // used for populating multiple choice values for sidebar filter in category and boolean fields
            return this.getLookupValues()
                .sort((a, b) => {
                    return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
                })
                .map(element => ({displayName: element}));
        },
        filterTypes: function () {
            // populate available filter operations depending on the type of field to be filtered
            if (!this.filterField) return [];

            const fieldType = this.config.fields.find(field => field.displayName == this.filterField).type;

            if (fieldType == 'numeric') return [
                { displayName: '>', tabulatorFunction: '>' },
                { displayName: '<', tabulatorFunction: '<' }
            ];
            return [
                { displayName: 'contains', tabulatorFunction: 'like' },
                { displayName: 'is exactly', tabulatorFunction: '=' },
            ];
        },
        canAddFilter: function () {
            if (this.showMultipleChoice) {
                return this.filterDropdown.multipleChoiceSelected;
            }
            if (!this.filterDropdown.type) {
                return false;
            }            
            if (!this.filterDropdown.inputFieldValue) {
                return false;
            }
            return true;
        },
        fieldGroupOrder: function () {
            return this.config.fieldGroups.map(e => e.name);
        }
    },
    methods: {
        getFilterDisplayValue(filter) {
            const myField = this.config.fields.find(f => f.key == filter.field)
            return filter.value === null ? 'No data' :
                getDisplayValue({field: myField, value: filter.value})
        },
        launchEdit(filter) {
            this.filterBeingEdited = filter;
            const myField = this.config.fields.find(field => field.key == filter.field)
            const fieldType = myField?.type;
            if (!fieldType) return;

            const getBooleanValue = () => filter.value ? myField.options[true] : myField.options[false]

            this.modals.fieldValue = fieldType == 'boolean' ?
                getBooleanValue() :
                getDisplayValue({field: myField, value: filter.value});

            this.editFilterHasMultipleChoice = (fieldType == 'category') || (fieldType == 'boolean');

            this.modals.editFilterTitle = `Edit filter: ${myField.displayName}`;

            if (this.editFilterHasMultipleChoice) {
                this.modals.lookupValues = this.getLookupValues({field: myField})
                    .sort((a, b) => {
                        return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
                    })
                    .map(element => ({displayName: element}));
            } else {
                this.modals.filterTypes = fieldType == 'numeric' ?
                    [{ displayName: '>', tabulatorFunction: '>' }, { displayName: '<', tabulatorFunction: '<' }]:
                    [{ displayName: 'contains', tabulatorFunction: 'like' }, { displayName: 'is exactly', tabulatorFunction: '=' }];
                this.modals.selectedType = filter.displayType;
            }

            this.$refs.editFilter.show();
        },
        updateFiltersFromModal() {
            const myField = this.config.fields.find(field => field.key == this.filterBeingEdited.field);

            this.filterBeingEdited.value = getGroupValue({field: myField, value: this.modals.fieldValue});
            
            if (!this.editFilterHasMultipleChoice) {
                this.filterBeingEdited.displayType = this.modals.selectedType;
                this.filterBeingEdited.type = allFilterTypes.find(e => e.displayName == this.modals.selectedType).tabulatorFunction;
            }

            this.filters = this.filters.concat([]); // triggers watch handler
        },
        addFilter({
            filterArr = [this.getFilterFromSidebar()]
        } = {}) {
            this.filters = this.filters.concat(filterArr);
            this.resetFilterDropdowns();
        },
        addMatrixFilter({i, j}) {
            const myMatrixFilters = getMatrixFilters({
                config: this.matrixConfig,
                fields: this.config.fields,
                i, j
            });
            
            this.addFilter({filterArr: myMatrixFilters});
            this.toggleMatrix();
        },
        toggleMatrix() {
            this.$emit('toggleMatrix');
        },
        getFilterFromSidebar({
            filterField = this.filterField,
            filterType = this.getFilterTypeFromSidebar(),
            field = this.getFieldByDisplayName(this.filterField),
            value = this.getFilterValueFromSidebar(),
        } = {}) {
            return {
                displayName: filterField, // not used by Tabulator
                field: field.key,
                type: filterType.type,
                displayType: filterType.displayType, // not used by Tabulator
                value: value
            }
        },
        getFilterTypeFromSidebar({
            selectedType = this.filterDropdown.type,
            filterTypes = this.filterTypes,
            isMultipleChoiceField = this.showMultipleChoice
        } = {}) {
            return {
                type: isMultipleChoiceField ?
                    'customEquals' :
                    filterTypes.find(element => element.displayName == selectedType).tabulatorFunction,
                displayType: isMultipleChoiceField ? ':' : selectedType,
            }
        },
        getBoolean(str, field) {
            if (!field.options?.true) {
                console.error('Boolean field is missing options parameter');
                return str;
            }
            return field.options.true.toLowerCase() == str.toLowerCase() ? 
                true : 
                field.options.false.toLowerCase() == str.toLowerCase() ?
                    false :
                    str;
        },
        getFilterValueFromSidebar({
            field = this.getFieldByDisplayName(this.filterField),
            isMultipleChoiceField = this.showMultipleChoice,
            inputFieldValue = this.filterDropdown.inputFieldValue,
            multipleChoiceSelected = this.filterDropdown.multipleChoiceSelected
        } = {}) {

            if (multipleChoiceSelected == 'No data') return null;

            if (!isMultipleChoiceField) return inputFieldValue;

            return field.type == 'boolean' ?
                this.getBoolean(multipleChoiceSelected, field) :
                getGroupValue({field, value: multipleChoiceSelected});
        },
        setFilters(filters) {
            if (!filters) return;
            this.filters = filters.filter(f => f !== undefined);
        },
        deleteFilter(filter) {
            // remove single filter
            this.filters = this.filters.filter(element => element !== filter);
            this.resetFilterDropdowns();
        },
        clearFilters() {
            // clear all filters
            this.filters = [];
            this.resetFilterDropdowns();
        },
        resetFilterDropdowns() {
            this.filterDropdown.multipleChoiceSelected = '';
            this.filterDropdown.inputFieldValue = '';
            this.filterField = '';
        },
        getLookupValues({
            field = this.getFieldByDisplayName(this.filterField),
            studentData = this.store.state.studentData,
            getValueFromObject = this.getValueFromObject,
        } = {}) {

            const myDisplayValue = (record, key) => {
                const myValue = getValueFromObject(record, key);
                if (myValue == null) return 'No data';
                return Array.isArray(myValue) ?
                    myValue[0].value:
                    myValue;
            };

            const getCategoryOptions = (data, key) => [...new Set(
                data.map(record => myDisplayValue(record, key))
            )].map(element => {
                if (element == 'No data') return 'No data';
                return getDisplayValue({value: element, field});
            });

            const getBooleanOptions = boolField => [
                boolField.options?.true || 'true',
                boolField.options?.false || 'false'
            ];

            const result = field.type == 'boolean' ? 
                getBooleanOptions(field):
                field.type == 'category' ?
                    getCategoryOptions(studentData, field.key):
                    [];
            return result;        
        },
        fieldsThatCanBeFiltered() {
            return this.config.fields.filter(element => !element.hideInFilters)
                .sort((a, b) => a.displayName > b.displayName ? 1 : -1);
        },
        getFieldByDisplayName(name) {
            return this.config.fields.find(field => field.displayName == name)
        },
        getValueFromObject(...args) {
            return getValueFromObject(...args)
        },
    }
}
</script>

<style lang="scss">

.edit-filter-button {
    font-size: 16px;
    &:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
}

</style>