<template>
    <div class="color-matrix">
        <div class="matrix-chart-container">
            <MyChart 
                :chartData="matrixConfig.chart.data"
                :chartConfig="matrixConfig.chart.config" 
                :stacked="true"
                :showAsPercentage="false"
                ref="matrixChart"
            />
        </div>

        <table class="matrix-table">
            <tr class="column-header-row">
                <th></th>
                <th></th>
                <th 
                    :colspan="matrixConfig.columns.length" 
                    class="column-super-header"
                >
                    {{ matrixConfig.columnHeader }}
                </th>
            </tr>
            <tr class="matrix-header-row">
                <th></th>
                <th></th>
                <th 
                    v-for="(heading, index) in matrixConfig.columns" 
                    :key="index"
                    class="column-header"
                >
                    {{ heading }}
                </th>
            </tr>
            <tr 
                v-for="(row, i) in matrixConfig.rows" 
                :key="i"
                class="matrix-body-row"
            >
                <td v-if="i==0" class="row-super-header" :rowspan="matrixConfig.rows.length">
                    {{ matrixConfig.rowHeader }}
                </td>
                <td class="row-header">{{ row }}</td>
                <td 
                    v-for="(col, j) in matrixConfig.columns" 
                    :key="j"
                    class="matrix-cell"
                    @click="addMatrixFilter(i, j)"
                    :style="getCellStyle()"
                    :class="matrixConfig.cellClasses[i][j]"
                >
                    {{ matrixConfig.cells[i][j] }}                    
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import MyChart from '@/components/charts.vue';

export default {
    props: {
        matrixConfig: Object
    },
    components: {
        MyChart
    },
    methods: {
        getCellStyle() {
            return `
                width: ${100 / (this.matrixConfig.columns.length + 1)}%;
                height: ${100 / (this.matrixConfig.rows.length + 2)}%;
            `;
        },
        addMatrixFilter(i, j) {
            this.$emit('addMatrixFilter', {i, j});
        }
    },
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';
    .color-matrix {
        padding: 0px 20px 60px 10px;
        width: 100%;
        height: calc(100vh - 50px);
        max-height: 600px;
    }

    .matrix-chart-container {
        width: 100%;
        height: 20%;
        margin-bottom: 40px;
    }

    .matrix-table {
        height: 75%;
        .column-super-header {
            height:10px;
        }
        .matrix-header-row {
            height:10px;
        }
        .row-super-header {
            writing-mode: vertical-rl;
            width:2%;
        }
        .matrix-cell {
            text-align: center;
            border-radius: 10px;
            &:hover {
                cursor: pointer;
            }
        }
        .problem {
            color: var(--color-bg);
            background-color: var(--color-warning);
            &:hover {
                background-color: var(--color-warning-light);
            }
        }
        .warning {
            color: var(--color-contrast-full);
            background-color: var(--color-info);
            &:hover {
                background-color: var(--color-info-light);
            }
        }
        .success {
            color: var(--color-contrast-full);
            background-color: var(--color-grade-b);
            &:hover {
                background-color: var(--color-success-light);
            }
        }
        .high-success {
            color: var(--color-bg);
            background-color: var(--color-success);
            &:hover {
                background-color: var(--color-success-light);
            }
        }
        .row-header {
            width:2%;
            text-align: right;
            padding: 15px;
        }
    }
</style>