<template>
<div class="sidebar-option" :class="visible">
    <div :title="tooltip" class="sidebar-option-title" @click="toggleMe()" @mouseover="showTooltip(description)">{{ title }}</div>
    <transition name="sidebar-trans">
        <div class="sidebar-option-content" v-if="visible">
            <slot></slot>
        </div>
    </transition>
</div>
</template>

<script>
export default {
    name: 'SidebarOption',
    props: {
        title: String,
        visibleOnStart: {
            type: Boolean,
            required: false,
            default: true
        },
        description: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            visible: this.visibleOnStart
        }
    },
    methods: {
        toggleMe() {
            this.visible = !this.visible;
        }
    },
    computed: {
        tooltip: function () {
            return (this.visible ? 'Hide' : 'Show') + ' ' + this.title;
        }
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors';

.sidebar-trans-enter-active, .sidebar-trans-leave-active {
    transition: all 0.2s ease;
}

.sidebar-trans-enter, .sidebar-trans-leave-to {
    opacity: 0;
}

.sidebar-option {
    padding-bottom: 20px;
    .sidebar-option-title {
        padding: 10px;
        font-weight: bold;
        background-color: var(--color-primary);
        color: var(--color-bg);
        transition: all 0.3s ease;
        &:hover {
            background-color: var(--color-primary-light);
            cursor: pointer;
        }            
    }
    .sidebar-option-content {
        padding: 0 0.5em 0;
    }
}

</style>