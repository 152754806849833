export const addTagFields = ({fields, tags}) => {

    let result = [...fields];

    if (!tags) return result;
    if (!tags.length) return result;

    tags.forEach(tag => {
        result.push({
            key: `Tag: ${tag.title}`,
            displayName: `Tag: ${tag.title}`,
            type: 'boolean',
            options: {
                true: `Tagged with ${tag.title}`,
                false: `Not Tagged with ${tag.title}`
            }
        });
    });

    return result;
};

export const addTagValues = ({data, tags}) => {

    let result = [...data];

    if (!tags) return result;
    if (!tags.length) return result;

    const hasTag = (record, tag) => {
        return record.tags?.[0]?.value ?
            record.tags[0].value.includes(tag.docID) ?
                    true:
                    false:
                false;
    };

    tags.forEach(tag => {
        result.forEach(record => {
            record[`Tag: ${tag.title}`] = hasTag(record, tag);
        });
    });

    return result;
};