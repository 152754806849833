/* eslint no-unused-vars: "off" */
<template>
    <div id="old-summary">
        <transition name="slide-fade">
            <Sidebar v-if="store.state.ui.sidebarVisible">
                <SidebarOption data-cy="protocol-display-area" title="Display">
                    <CustomSelect :options="formatOptions" displayField="displayName" title="Number Format" v-model="selectedFormatOption" />
                </SidebarOption>
                <SidebarOption title="Download">
                    <CustomSelect :options="downloadOptions" displayField="displayName" title="Table to Download" v-model="selectedDownloadOption" />
                    <CustomButton @click="download" buttonText="Download" />
                </SidebarOption>
                <SidebarOption title="Data Last Updated">
                    <p>PowerSchool: {{ freshness.powerschool }}</p>
                    <p>Naviance: {{ freshness.naviance }}</p>
                    <p>FAFSA: {{ freshness.fafsa }}</p>
                </SidebarOption>
            </Sidebar>
        </transition>
        <div :class="getMainAreaWidthClass">
            <div class="scroll-area">
                <div v-for="(block, index) in protocolBlocks" :key="index">
                    <h2 class="subheading">{{ block.heading }}</h2>
                    <p class="explanation">{{ block.explanation }}</p>
                    <div class="table-area" v-for="(table, j) in block.tables" :key="j">
                        <p class="table-title">{{ table.tableTitle }}</p>
                        <MyTable
                            :ref="table.ref"
                            :id="table.ref"
                            :tableData="table.tableBody.data"
                            :columns="table.tableBody.columns"
                            :customConfig="summaryTableConfig"
                            :showAsInlineTable="true"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import { getMainAreaWidthClass } from '@/functions/utils.js';
import { getSummaryDataAndColumns, getVictorySummaryDataAndColumns, getRedirectCustomProp } from '@/functions/summary.js';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import Sidebar from '@/components/sidebar.vue';
import SidebarOption from '@/components/sidebarOption.vue';
import MyTable from '@/components/table.vue';

export default {
    components: { Sidebar, SidebarOption, MyTable, CustomSelect, CustomButton },
    props: {},
    data() {
        return {
            freshness: this.getFreshness(),
            selectedDownloadOption: 'All',
            protocolBlocks: [
                {
                    heading: 'Postsecondary Plans',
                    explanation: 'Table 1a summarizes students’ postsecondary plans. Through surveys and conversations with counselors, students indicate their plans to pursue one or multiple pathway(s). Please note that students may appear in multiple rows if they are exploring multiple pathways. As a network, our goal is to ensure all students graduate with a postsecondary plan; therefore, the “No Selected Plan” designation is an area of focus. Table 1b provides students’ postsecondary plan information broken down by race/ethnicity.',
                    tables: [
                        {
                            tableTitle: 'Table 1a: Postsecondary plans',
                            rowGroup: 'plans',
                            columnGroup: 'school.name',
                            tableBody: this.getSummaryTable('plans', 'school.name', true),
                            percentageByGroup: true,
                            ref: 'protocol-table-1a'
                        },
                        {
                            tableTitle: 'Table 1b: Postsecondary plans by race/ethnicity',
                            rowGroup: 'plans',
                            columnGroup: 'raceAndEthnicity.race',
                            tableBody: this.getSummaryTable('plans', 'raceAndEthnicity.race', false),
                            percentageByGroup: false,
                            ref: 'protocol-table-1b'
                        },
                    ]
                },
                {
                    heading: 'College Applications',
                    explanation: 'Table 2a shows students in rows based on their postsecondary plans; students appear in the appropriate column based on the number of college applications submitted by each student. Table 2b shows students in rows based on their race/ethnicity; students appear in the appropriate column based on the number of college applications submitted by each student. College application data pull directly from Naviance; therefore, it is important for students to regularly update their Naviance accounts with their college application details.',
                    tables: [
                        {
                            tableTitle: 'Table 2a: Number of college applications completed by postsecondary plan',
                            rowGroup: 'plans',
                            columnGroup: '_collegeAppsSubmitted',
                            tableBody: this.getSummaryTable('plans', '_collegeAppsSubmitted', false),
                            percentageByGroup: false,
                            ref: 'protocol-table-2a'
                        },
                        {
                            tableTitle: 'Table 2b: Number of college applications completed by race/ethnicity',
                            rowGroup: 'raceAndEthnicity.race',
                            columnGroup: '_collegeAppsSubmitted',
                            tableBody: this.getSummaryTable('raceAndEthnicity.race', '_collegeAppsSubmitted', false),
                            percentageByGroup: false,
                            ref: 'protocol-table-2b'
                        },
                    ]
                },
                {
                    heading: 'FAFSA Review',
                    explanation: 'This section provides information about students’ FAFSA completion status. The data presented in the tables below are from the U.S. Department of Education via Connecticut’s EdSight portal. High schools are unable to track AACTUS completion for students without social security numbers. Table 3a summarizes FAFSA status for all students in the selected grade. Tables 3b and 3c summarize FAFSA status by postsecondary plan and race/ethnicity.',
                    tables: [
                        {
                            tableTitle: 'Table 3a: FAFSA status',
                            rowGroup: 'FAFSAStateStatus',
                            columnGroup: 'school.name',
                            tableBody: this.getSummaryTable('FAFSAStateStatus', 'school.name', true),
                            percentageByGroup: true,
                            ref: 'protocol-table-3a',
                            fieldsToAddOnRedirect: ['FAFSAStatus']
                        },
                        {
                            tableTitle: 'Table 3b: FAFSA status by postsecondary plan',
                            rowGroup: 'plans',
                            columnGroup: 'FAFSAStateStatus',
                            tableBody: this.getSummaryTable('plans', 'FAFSAStateStatus', false),
                            percentageByGroup: false,
                            ref: 'protocol-table-3b',
                            fieldsToAddOnRedirect: ['FAFSAStatus']
                        },
                        {
                            tableTitle: 'Table 3c: FAFSA status by race/ethnicity',
                            rowGroup: 'FAFSAStateStatus',
                            columnGroup: 'raceAndEthnicity.race',
                            tableBody: this.getSummaryTable('FAFSAStateStatus', 'raceAndEthnicity.race', false),
                            percentageByGroup: false,
                            ref: 'protocol-table-3c',
                            fieldsToAddOnRedirect: ['FAFSAStatus']
                        },
                    ]
                },
                {
                    heading: 'College Access Milestones',
                    explanation: 'This section summarizes students’ progress completing key college access milestones. Students appear in the charts below if they have indicated plans to pursue either a 2- or 4-year college degree. Data in these tables draw from Naviance and student surveys, and counselors may enter data for specific variables based on their knowledge of student progress. Table 4a lists college access milestones and the number of students having completed each access milestone.',
                    tables: [
                        {
                            tableTitle: 'Table 4a: College-bound milestone completion',
                            type: 'victory',
                            rowFields: ['collegeApps', 'navianceCollegeList', 'studentRequestedRecs',  'teacherSubmittedRecs'],
                            columnGroup: 'raceAndEthnicity.race',
                            tableBody: this.getVictoryTable(['collegeApps', 'navianceCollegeList', 'studentRequestedRecs',  'teacherSubmittedRecs'], 'raceAndEthnicity.race'),
                            ref: 'protocol-table-4a'
                        }
                    ]
                },
            ],
            summaryTableConfig: {
                height: '100%',
                width: '100%',
                cellDblClick: (e, cell) => {
                    this.redirectToTracker(cell);
                },
            },
            selectedFormatOption: 'Show count',
            formatOptions: [
                {displayName: 'Show count'},
                {displayName: 'Show percentage'},
                {displayName: 'Show count and percentage'}
            ]
        }
    },
    methods: {
        getFreshness() {
            const mySchool = this.store.state.schools.find(element => element.key == this.store.state.chosenSchool);
            
            const myDate = str => new Date(str + 'T12:00').toDateString();

            return mySchool ? {
                powerschool: myDate(mySchool.powerschoolLastUpdate),
                naviance: myDate(mySchool.navianceLastUpdate),
                fafsa: myDate(mySchool.FAFSALastUpdate)
            } : {
                powerschool: '',
                naviance: '',
                fafsa: '',
            };
        },
        redirectToTracker(cell) {
            // navigate to tracker page and show relevant students

            const myTable = this.protocolBlocks.map(e => e.tables)
                .flat()
                .find(e => e.ref == cell._cell.table.element.parentElement.parentElement.id);
            
            // do not redirect for victory table
            if (myTable.type == 'victory') return;

            // if user double-clicked on category column(s), ignore
            if (cell._cell.value == cell._cell.row.cells[0].value) return;

            // if there is no tracker, ignore
            if (!this.config.applets.includes('tracker')) return;

            const redirectDefaultFields = this.config.analyticsConfig.redirectDefaultFields
                .concat(myTable.fieldsToAddOnRedirect || []);
            
            this.store.setCustomProp(getRedirectCustomProp({
                rowKey: myTable.rowGroup,
                columnKey: myTable.columnGroup,
                fields: this.config.fields,
                rowValue: cell._cell.row.cells[0].value,
                rowSubValue: cell._cell.row.cells[1].value,
                columnValue: cell._cell.column.field,
                redirectDefaultFields
            }));

            this.$router.push('/tracker');
        },
        getSummaryTable(rowGroup, columnGroup, percentageByGroup=false) {
            return getSummaryDataAndColumns({
                studentData: this.store.state.studentData,
                tags: this.store.state.tags,
                fields: this.config.fields,
                rowKey: rowGroup,
                columnKey: columnGroup,
                selectedFormatOption: this.selectedFormatOption || 'Show count',
                showSumRow: true,
                percentageByGroup
            });
        },
        getVictoryTable(rowFields, columnGroup) {
            return getVictorySummaryDataAndColumns({
                data: this.store.state.studentData,
                fields: this.config.fields,
                rowFieldKeys: rowFields,
                columnKey: columnGroup,
                selectedFormatOption: this.selectedFormatOption || 'Show count',
            });
        },
        renderAllTables() {
            if (!this.config.applets.includes('summarytables')) return;
            this.protocolBlocks.forEach(block => {
                block.tables.forEach(table => {
                    table.tableBody = table.type == 'victory' ?
                        this.getVictoryTable(table.rowFields, table.columnGroup) :
                        this.getSummaryTable(table.rowGroup, table.columnGroup, table.percentageByGroup);
                    this.$refs[table.ref].setDataAndColumns(table.tableBody);
                });
            });
        },
        download() {
            const myOption = this.downloadOptions.find(element => element.displayName == this.selectedDownloadOption);
            if (myOption.ref) {
                this.$refs[myOption.ref].download();
            } else {
                let sheets = {};
                const tables = this.downloadOptions.filter(element => element.ref);
                const nodes = document.querySelectorAll('.table-area > div:first-of-type > div > div');
                for (let i = 0, sheetName = ''; i < nodes.length; i++) {
                    sheetName = tables[i].displayName.substr(0, 30).replaceAll(':', '');
                    sheets[sheetName] = nodes[i];
                }
                const firstTable = this.downloadOptions.find(element => element.ref);
                this.$refs[firstTable.ref].downloadMultiple(sheets);
            }

            // this.fb.events.add({ action: 'download', target: 'summaryTables' });
        }
    },
    watch: {
        selectedFormatOption: function () {
            this.renderAllTables();
        }
    },
    computed: {
        getMainAreaWidthClass: function () {
            // returns the CSS class for the main area
            return getMainAreaWidthClass(this.store.state.ui.sidebarVisible, this.store.state.ui.chatVisible);
        },
        downloadOptions: function () {
            let result = [{displayName: 'All'}];
            this.protocolBlocks.forEach(block => {
                block.tables.forEach(table => {
                    result.push({displayName: table.tableTitle, ref: table.ref});
                });
            });
            return result;
        }
    },
    mounted() {
        this.ee.on('studentDataReceived', () => {
            // if there is no summary tables page, ignore
            if (!this.config.applets.includes('summarytables')) return;
            this.renderAllTables();
        });

        this.ee.on('gradeLevelChange', newLevel => {
            // if there is no summary tables page, ignore
            if (!this.config.applets.includes('summarytables')) return;
            this.renderAllTables();
        });

        this.ee.on('schoolChanged', () => {
            // if there is no summary tables page, ignore
            if (!this.config.applets.includes('summarytables')) return;
            this.freshness = this.getFreshness();
        });
    },
    activated() {
        this.renderAllTables();
        // this.fb.events.add({ action: 'view', target: 'summarytables' });
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/mystyles.scss';

    .subheading, .explanation, .table-title {
        margin-bottom: 2rem;
    }
    .table-title {
        font-weight: bold;
    }
    .table-area {
        padding-right: 1.5rem;
    }
</style>