/* eslint no-unused-vars: "off" */
<template>
    <div id="summary-app">
        <transition name="slide-fade">
            <Sidebar v-if="store.state.ui.sidebarVisible">
                <SidebarOption title="Display">
                    <CustomSelect data-cy="analytics-toggle-chart-view" :options="modes" displayField="displayName" title="Show" v-model="mode" />
                    <CustomSelect data-cy="analytics-format-options" :options="formatOptions" displayField="displayName" title="Number Format" v-model="selectedFormatOption" />
                </SidebarOption>
                <SidebarOption title="Grouping">
                    <CustomSelect data-cy="analytics-row-grouping" :options="discreteFields" displayField="displayName" title="Row Grouping" v-model="inputs.rowGroup" />
                    <CustomSelect data-cy="analytics-row-sub-grouping" v-show="mode=='Table'" :options="discreteFieldsNullable" displayField="displayName" title="Row Sub-grouping (optional)" v-model="inputs.rowSubGroup" />
                    <CustomSelect data-cy="analytics-column-grouping" :options="discreteFields" displayField="displayName" title="Column Grouping" v-model="inputs.columnGroup" />
                </SidebarOption>
            </Sidebar>
        </transition>
        <div :class="getMainAreaWidthClass">
            <div class="scroll-area" v-show="mode=='Table'">
                <h2 class="tableheading">{{ getHeading() }}</h2>
                <MyTable 
                    data-cy="analytics-table"
                    :tableData="summary.data" 
                    :columns="summary.columns" 
                    :customConfig="summaryTableConfig" 
                    :showAsInlineTable="true"
                    ref="summaryTable"
                />
            </div>
            <div v-show="mode.includes('Chart')">
                <MyChart 
                    data-cy="analytics-chart"
                    :chartData="chartData"
                    :chartConfig="chartConfig" 
                    ref="summaryChart"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MyTable from '@/components/table.vue';
import MyChart from '@/components/charts.vue';
import Sidebar from '@/components/sidebar.vue';
import SidebarOption from '@/components/sidebarOption.vue';
import CustomSelect from '@/components/customSelect.vue';
import { getSummaryDataAndColumns, getRedirectCustomPropFromChart, getRedirectCustomProp } from '@/functions/summary.js';
import { addTagFields, addTagValues } from '@/functions/tagFunctions.js';
import { getMainAreaWidthClass } from '@/functions/utils.js';

export default {
    name: 'Analytics',
    data() {
        return {
            summary: getSummaryDataAndColumns({
                studentData: this.store.state.studentData, 
                tags: this.store.state.tags,
                fields: this.config.fields,
                rowKey: this.config.analyticsConfig.rowGroup,
                columnKey: this.config.analyticsConfig.columnGroup,
                selectedFormatOption: 'Show count',
                showSumRow: true
            }),
            inputs: {
                rowGroup: this.config.fields.find(element => element.key == this.config.analyticsConfig.rowGroup).displayName,
                rowSubGroup: 'None',
                columnGroup: this.config.fields.find(element => element.key == this.config.analyticsConfig.columnGroup).displayName,
            },
            discreteFieldsNullable: this.getDiscreteFieldsNullable(),
            discreteFields: this.getDiscreteFields(),
            summaryTableConfig: {
                height: '100%',
                cellDblClick: (e, cell) => {
                    this.redirectToTracker(cell);
                },
            },
            mode: 'Table',
            modes: [
                {displayName: 'Table'},
                {displayName: 'Bar Chart'},
                {displayName: 'Stacked Bar Chart'}
            ],
            selectedFormatOption: 'Show count',
            formatOptions: [
                {displayName: 'Show count'},
                {displayName: 'Show percentage'},
                {displayName: 'Show count and percentage'}
            ],
            loading: false,
            chartData: this.addTagValues({data: this.store.state.studentData, tags: this.store.state.tags}),
            chartFields: this.addTagFields({fields: this.config.fields, tags: this.store.state.tags})
        };
    },
    watch: {
        inputs: {
            deep: true,
            handler() {
                this.renderSummaryTable();
            }
        },
        selectedFormatOption: function () {
            this.renderSummaryTable();
        }
    },
    methods: {
        addTagFields,
        addTagValues,
        setEventListeners() {
            [
                {
                    name: 'launchApp',
                    fn: () => this.launch()
                },
                {
                    name: 'studentDataReceived',
                    fn: () => this.refreshDisplay()
                },
                {
                    name: 'gradeLevelChange',
                    fn: () => this.refreshDisplay()
                },
            ].forEach(event => {
                this.ee.on(event.name, event.fn);
            });
        },
        launch() {
            if (!this.config.applets.includes('analytics')) return;

            this.inputs = {
                rowGroup: this.config.fields.find(element => element.key == this.config.analyticsConfig.rowGroup).displayName,
                rowSubGroup: 'None',
                columnGroup: this.config.fields.find(element => element.key == this.config.analyticsConfig.columnGroup).displayName,
                // showSumRow: false,
            };
            this.loading = false;
        },
        redirectToTracker(cell) {
            // navigate to tracker page and show students that match current filters
            
            // if user double-clicked on category column(s), ignore
            if (cell._cell.column.field == this.summaryKeys.rowGroup) return;
            if (cell._cell.column.field == this.summaryKeys.rowSubGroup) return;

            // if there is no tracker, ignore
            if (!this.config.applets.includes('tracker')) return;

            const myProp = getRedirectCustomProp({
                rowKey: this.summaryKeys.rowGroup,
                rowSubKey: this.summaryKeys.rowSubGroup,
                columnKey: this.summaryKeys.columnGroup,
                fields: this.config.fields,
                rowValue: cell._cell.row.cells[0].value,
                rowSubValue: cell._cell.row.cells[1].value,
                columnValue: cell._cell.column.field,
                redirectDefaultFields: this.config.analyticsConfig.redirectDefaultFields
            });

            if (!myProp) return;

            this.store.setCustomProp(myProp);

            this.$router.push('/tracker');
        },
        renderSummaryTable() {
            if (this.$route.name !== 'Analytics') return;
            if (!this.$refs.summaryTable) return;
            if (this.loading) return;

            this.summary = getSummaryDataAndColumns({
                studentData: this.store.state.studentData,
                tags: this.store.state.tags,
                fields: this.config.fields,
                rowKey: this.summaryKeys.rowGroup,
                rowSubKey: this.summaryKeys.rowSubGroup,
                columnKey: this.summaryKeys.columnGroup,
                selectedFormatOption: this.selectedFormatOption,
                showSumRow: true
            });

            this.$refs.summaryTable.setDataAndColumns(this.summary);
        },
        refreshDisplay() {
            this.renderSummaryTable();
            this.refreshChart();            
        },
        refreshChart() {
            if (!this.$refs.summaryChart) return;
            if (this.$route.name !== 'Analytics') return;

            this.chartData = this.addTagValues({data: this.store.state.studentData, tags: this.store.state.tags});
            this.chartFields = this.addTagFields({fields: this.config.fields, tags: this.store.state.tags});
        },
        getHeading() {
            return this.inputs.rowSubGroup == 'None' ?
                `${this.inputs.rowGroup} and ${this.inputs.columnGroup}`:
                `${this.inputs.rowGroup}, ${this.inputs.rowSubGroup} and ${this.inputs.columnGroup}`;
        },
        getDiscreteFields() {
            let result = [...this.config.fields];
            
            result = result.filter(element => element.type == 'discrete' || element.type == 'boolean' || element.type == 'category');

            if (!this.$user.hasPower('viewDataByCounselor')) {
                result = result.filter(element => element.key !== 'counselorName');
            }

            // add tags
            if (this.config.fields.find(field => field.key == 'tags')) {
                this.store.state.tags.forEach(tag => {
                    result.push({
                        displayName: `Tag: ${tag.title}`
                    });
                })
            }
            
            return result.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
        },
        getDiscreteFieldsNullable() {
            return [{
                displayName: 'None'
            }].concat(this.getDiscreteFields());
        },
        getFieldKey(displayName) {
            if (displayName.slice(0,4) == 'Tag:') {
                return displayName;
            }
            return this.config.fields.find(field => field.displayName == displayName).key
        },
        chartClickHandler({filters}) {
            const myFilters = filters.filter(f => f !== undefined);
            this.store.setCustomProp(getRedirectCustomPropFromChart({
                filters: myFilters,
                redirectDefaultFields: this.config.analyticsConfig.redirectDefaultFields,
                fields: this.config.fields,
            }));

            this.$router.push('/tracker');
        },
    },
    mounted() {
        this.setEventListeners();
    },
    activated() {
        this.discreteFieldsNullable = this.getDiscreteFieldsNullable();
        this.discreteFields = this.getDiscreteFields();
        this.refreshDisplay();
        // this.fb.events.add({ action: 'view', target: 'analytics' });
    },
    computed: {
        summaryKeys: function () {
            return {
                rowGroup: this.getFieldKey(this.inputs.rowGroup),
                rowSubGroup: this.inputs.rowSubGroup == 'None' ? null: this.getFieldKey(this.inputs.rowSubGroup),
                columnGroup: this.getFieldKey(this.inputs.columnGroup),
            }
        },
        showAsPercentage: function () {
            return this.selectedFormatOption == 'Show percentage';
        },
        getMainAreaWidthClass: function () {
            return getMainAreaWidthClass(this.store.state.ui.sidebarVisible, this.store.state.ui.chatVisible);
        },
        chartConfig: function () {
            return {
                type: 'grouped',
                title: `${this.inputs.rowGroup} and ${this.inputs.columnGroup}`,
                primaryField: this.chartFields.find(field => field.key == this.summaryKeys.rowGroup),
                secondaryField: this.chartFields.find(field => field.key == this.summaryKeys.columnGroup),
                stacked: this.mode == 'Stacked Bar Chart',
                showAsPercentage: this.showAsPercentage,
                selectedFormatOption: this.selectedFormatOption,
                onClick: this.chartClickHandler
            };
        },
    },
    components: {
        MyTable, CustomSelect, SidebarOption, Sidebar, MyChart
    },
}
</script>

<style lang="scss">
    @import '../assets/styles/mystyles.scss';

    .tableheading {
        margin-bottom: 2rem;
        margin-top:0;
    }
</style>

