<template>
    <div class="chart-container">
        <canvas ref="myChart"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getChart } from '@/functions/charts.js';
import variables from '@/assets/styles/colors.scss';

Chart.defaults.font.family = `Lato, 'Helvetica Neue', 'Arial', sans-serif`;
Chart.defaults.font.size = 16;
Chart.register(ChartDataLabels);

export default {
    name: 'MyChart',
    props: {
        chartData: Array,
        chartConfig: Object,
    },
    data() {
        /**
         * This unusual setup is a fix to a maximum call stack error. See the following for details:
         * https://stackoverflow.com/questions/68602389/maximum-call-stack-error-when-attempting-to-update-chart-in-vue-js
         * 
         */
        this.chart = null;
        return {};
    },
    watch: {
        chartConfig: function () {
            this.renderChart();
        },
        chartData: function () {
            this.renderChart();
        },
    },
    methods: {
        setDefaultColors () {
            const theme = this.store.state.ui.currentColorTheme;
            Chart.defaults.color = variables[`${theme}contrastFull`];
            Chart.defaults.backgroundColor = variables[`${theme}bg`];
            Chart.defaults.borderColor = variables[`${theme}contrastLow`];
        },
        renderChart () {
            try {
                if (!this.chartConfig) return;
                if (!this.chartConfig.type) return;

                const myConfig = getChart({
                    chartData: this.chartData,
                    chartConfig: this.chartConfig
                });

                if (!myConfig) return;

                this.setDefaultColors();

                if (this.chart) {
                    this.chart.data = myConfig.data;
                    this.chart.options = myConfig.options;
                    this.chart.update();
                } else {
                    this.chart = new Chart(this.$refs.myChart, myConfig);
                }
            } catch (e) {
                console.error(e);
            }
        }
    },
    mounted: function () {
        this.renderChart();
        this.ee.on('colorThemeChanged', () => {
            this.renderChart();
        });
    }
}
</script>

<style lang="scss">
.chart-container {
    position: relative; 
    height: 100%; 
    width: 100%;
}
</style>
