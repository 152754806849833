import { getValueFromObject, getDisplayValue } from '@/functions/utils.js';

export const getRecordValue = (record, fieldKey, fields) => {
    const field = fields.find(element => element.key == fieldKey);
    if (!field) return;
    const value = getValueFromObject(record, field.key);
    return getDisplayValue({field, value});
};

export const getDisplayClass = (record, fieldKey, fields) => {

    // format record field as victory, no victory, or neither

    const myField = fields.find(element => element.key == fieldKey);
    if (!myField) return;
    
    let myValue = getValueFromObject(record, myField.key);

    if (myField.victory) {
        return myField.victory(myValue) ? 'victory' : 'noVictory'
    }
    return 'entry';
};

const styles = {
    header: {
        fontSize: 18,
        bold: true
    },
    tableHeader: {
        fontSize: 14,
        bold: true
    },
    myTable: {
        margin: [0, 5, 0, 15]
    },
    entry: {
        bold: true
    },
    victory: {
        bold: true,
        color: 'green'
    },
    noVictory: {
        bold: true,
        color: 'red'
    },
};

export const getDetailsPDF = ({
    student,
    fieldGroups,
    fields,
    getValue=getRecordValue,
    getClass=getDisplayClass
}) => {

    let content = [
        {
            text: [student.firstName, student.lastName].join(' '),
            style: 'header'
        },
    ];

    fieldGroups.forEach(group => {
        const obj = {
            style: 'myTable',
            table: {
                headerRows: 1,
                widths: [200, 'auto'],
                body: [
                    [{text: group.name, style: 'tableHeader'}, {text: '', style: 'tableHeader'}],
                ]
            },
            layout: 'noBorders'
        };

        group.fields.forEach(field => {
            obj.table.body.push(
                [
                    field.name,
                    {
                        text: getValue(student, field.key, fields),
                        style: getClass(student, field.key, fields)
                    }
                ]
            );
        })

        content.push(obj);
    });

    return {content, styles};
};