import { getValueFromObject, getGroupValue, distinctSet, orderSet, getDisplayValue } from '@/functions/utils.js';
import variables from '@/assets/styles/colors.scss';

const colors = [
    '#c82128', '#ffc107', '#98d49b', '#009f4a', '#263c7f', 
    '#7089d3', '#3ddd88', '#ffe083', '#e6676e', 
    '#785c9c', '#a590c0', '#75ed56', '#bc65a3', 
];

const getRecordCount = ({data, fieldKey, value}) => data.filter(element =>                         
    getValueFromObject(element, fieldKey) == value
).length;

const charts = {};

charts.pie = ({chartData, chartConfig}) => {

    const labels = distinctSet({
        fieldKey: chartConfig.primaryField.key, 
        data: chartData
    }).sort((a, b) => orderSet({
        a: chartConfig.primaryField.options?.[a] || a, 
        b: chartConfig.primaryField.options?.[b] || b, 
        field: chartConfig.primaryField
    }));

    const dataPoints = labels.map(category => {
        category = getDisplayValue({
            value: category, 
            field: chartConfig.primaryField, 
        });        
        return chartData.filter(element => getValueFromObject(element, chartConfig.primaryField.key) == category).length;
    });

    const backgroundColor = chartConfig.primaryField.bands ?
        chartConfig.primaryField.bands.map(band => variables[`default${band.colorCode}`]):
        colors;

    const data = {
        labels: labels.map(label => chartConfig.formatLabel(label)),
        datasets: [{
            label: '',
            data: dataPoints,
            backgroundColor,
            hoverOffset: 4
        }]
    };

    return {
        type: 'pie',
        data: data,
        options: {
            plugins: {
                legend: {
                    position: 'right'
                },
                datalabels: {
                    formatter(value, context) {
                        const percent = Math.round((value / chartData.length) * 100)
                        return `${value} (${percent}%)`;
                    }
                }
            },
            onClick: function (e, arr) {
                const filters = [{
                    key: chartConfig.primaryField.key,
                    value: labels[arr[0].index]
                }];

                chartConfig.onClick({filters});
            }
        }
    };
};

charts.singleBand = ({chartData, chartConfig}) => {

    const bands = chartConfig.primaryField.bands;

    if (!bands) {
        console.error('Bands missing from field config');
        return {};
    }

    const getCount = band => chartData.filter(
        element => band.test(
            getValueFromObject(element, chartConfig.primaryField.key)
        )
    ).length;   

    const getPercentage = ({band, totalRecords}) => getCount(band) * 100 / totalRecords;

    const max = bands
        .map(band => getCount(band))
        .reduce((a, b) => a + b, 0); // sum of records

    const data = {
        labels: [chartConfig.label || ''],
        datasets: bands.map(band => ({
            label: band.label,
            data: [getPercentage({band, totalRecords: max})],
            backgroundColor: variables[`default${band.colorCode}`]
        }))
    };

    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    stacked: true,
                },
                x: {
                    stacked: true,
                    max: 100
                }
            },
            aspectRatio: 8,
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                },
                title: {
                    display: true,
                    text: chartConfig.title || '',
                    font: {
                        size: 18
                    }
                },
                datalabels: {
                    formatter(value, context) {
                        if (value == 0) return '';
                        const count = Math.round(value * max / 100);
                        return `${count} (${Math.round(value)}%)`;
                    }
                }
            }
        }
    };
};

charts.matrix = ({chartData, chartConfig}) => {

    const bgColors = ['defaultwarning', 'defaultinfo', 'defaultgradeB', 'defaultsuccess'];
    const myColors = ['defaultbg', 'defaultcontrastFull', 'defaultcontrastFull', 'defaultbg'];

    const datasets = chartData.map((element, i) => ({
        label: chartConfig.labels[i],
        data: [element],
        backgroundColor: variables[bgColors[i]],
        datalabels: {
            color: variables[myColors[i]],
        }
    }));

    const data = {
        labels: [''],
        datasets
    };

    const max = chartData.reduce((a, b) => a + b, 0); // sum of records
                
    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    stacked: true,
                },
                x: {
                    stacked: true,
                    max
                }
            },
            aspectRatio: 8,
            indexAxis: 'y',
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: true,
                    text: chartConfig.title,
                    font: {
                        size: 18
                    }
                }
            },
        }
    };
};

const getNumberFormat = ({count, percentage, selectedFormatOption}) => [
    {
        mode: 'Show count',
        str: `${count}`
    },
    {
        mode: 'Show percentage',
        str: `${percentage}%`
    },
    {
        mode: 'Show count and percentage',
        str: `${count} (${percentage}%)`
    }
].find(element => element.mode == selectedFormatOption).str;

charts.grouped = ({chartData, chartConfig}) => {

    const stacked = chartConfig.stacked;

    if (!chartConfig.primaryField) return {};

    const labels = distinctSet({
        fieldKey: chartConfig.primaryField.key, 
        data: chartData
    }).sort((a, b) => orderSet({
        a: chartConfig.primaryField.options?.[a] || a, 
        b: chartConfig.primaryField.options?.[b] || b, 
        field: chartConfig.primaryField
    }));

    const groups = distinctSet({
        fieldKey: chartConfig.secondaryField.key, 
        data: chartData
    }).map(group => ({
        label: getDisplayValue({
            value: group, 
            field: chartConfig.secondaryField, 
        }),
        value: group
    })).sort((a, b) => orderSet({
        a: a.value, 
        b: b.value, 
        field: chartConfig.secondaryField
    }));

    const source = labels.map(category => {
        const result = {};

        const totalRecords = getRecordCount({
            data: chartData, 
            fieldKey: chartConfig.primaryField.key, 
            value: category
        });

        groups.forEach(group => {
            const myRecords = chartData.filter(element =>                         
                getValueFromObject(element, chartConfig.primaryField.key) == category && 
                getValueFromObject(element, chartConfig.secondaryField.key) == group.value
            );

            result[group.label] = chartConfig.showAsPercentage ?
                myRecords.length * 100 / totalRecords:
                myRecords.length;
        });

        return result;
    });

    const datasets = groups.map((group, i) => ({
        label: group.label,
        data: source.map(e => e[group.label]),
        backgroundColor: colors[i],
    }));

    const data = {
        labels: labels.map(element => getDisplayValue({field: chartConfig.primaryField, value: element})),
        datasets
    };

    return {
        type: 'bar',
        data,
        options: {
            scales: {
                y: {
                    stacked,
                },
                x: {
                    stacked,
                }
            },
            indexAxis: stacked ? 'y' : 'x',
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: chartConfig.title,
                    font: {
                        size: 18
                    }
                },
                datalabels: {
                    formatter(value, context) {
                        if (value == 0) return '';

                        const percentage = chartConfig.selectedFormatOption == 'Show percentage' ?
                            Math.round(value):
                            Math.round((value / chartData.length) * 100);

                        return getNumberFormat({
                            count: value, 
                            percentage, 
                            selectedFormatOption: chartConfig.selectedFormatOption
                        });
                    }
                }
            },
            onClick: function (e, arr) {
                const filters = [
                    {
                        key: chartConfig.primaryField.key,
                        value: labels[arr[0].index]
                    },
                    {
                        key: chartConfig.secondaryField.key,
                        value: getGroupValue({
                            field: chartConfig.secondaryField,
                            value: datasets[arr[0].datasetIndex].label
                        })
                    },
                ];
                chartConfig.onClick({filters});
            }
        }
    };
};

export const getChart = ({chartData, chartConfig}) => charts[chartConfig.type]({chartData, chartConfig});
