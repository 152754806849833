import { getValueFromObject, distinctSet, getGroupValue, orderSet, getDisplayValue } from '@/functions/utils.js'
import { addTagFields, addTagValues } from '@/functions/tagFunctions.js';
import { processTrueFalse } from './utils';

const dataMissingWarning = 'No data';

// apply user-defined filters
const applyFilters = ({data, filters}) => {
    let result = data;

    filters.forEach(filter => {
        if (filter.type == '=' || !filter.type) {
            result = result.filter(record => getValueFromObject(record, filter.field) == filter.value);
        } else if (filter.type == '>') {
            result = result.filter(record => getValueFromObject(record, filter.field) > filter.value);
        } else if (filter.type == '<') {
            result = result.filter(record => getValueFromObject(record, filter.field) < filter.value);
        }
    });

    return result;
};

const getFieldDisplayName = ({key, fields}) => {
    if (key === null || key === 'None') return null;
    return fields.find(element => element.key == key)?.displayName;
};

const clean = val => {
    if (val === undefined || val === null) return 'noData';
    return typeof val === 'string' ? val.replaceAll('.', '_') : val;
};

const unclean = val => typeof val === 'string' ? val.replaceAll('_', '.') : val;

const getUniqueSet = ({key, fields, data}) => {
    if (key === null) return [undefined];
    const myField = fields.find(field => field.key == key);
    return distinctSet({fieldKey: key, data})
        .sort((a, b) => orderSet({a, b, dataMissingWarning, field: myField}));
};

const getRecordTotal = ({data, category, rowKey, rowSubKey, subCategory}) => data.filter(record => {
        if (category === undefined) return true;
        return getValueFromObject(record, rowKey) == category
    }).filter(record => {
        if (subCategory === undefined) return true;
        return getValueFromObject(record, rowSubKey) == subCategory
    }).length;

// function to build each row in the table

const getRecords = ({data, columnKey, column, rowKey, category, rowSubKey, subCategory}) => data.filter(record => 
        getValueFromObject(record, columnKey) == column
    ).filter(record => {
        if (category === undefined) return true;
        return getValueFromObject(record, rowKey) == category
    }).filter(record => {
        if (subCategory === undefined) return true;
        return getValueFromObject(record, rowSubKey) == subCategory
    });

const getPercentage = ({percentageByGroup, myRecords, recordTotal, data}) => percentageByGroup ?
    (myRecords.length * 100 / data.length).toFixed(0) + '%' :
    recordTotal ?
        (myRecords.length * 100 / recordTotal).toFixed(0) + '%' :
        'N/A'

const formatCellValue = ({count, percentage, selectedFormatOption}) => [
    {
        mode: 'Show count',
        str: count
    },
    {
        mode: 'Show percentage',
        str: percentage
    },
    {
        mode: 'Show count and percentage',
        str: `${count} (${percentage})`
    }
].find(element => element.mode == selectedFormatOption).str;

const createTableRow = ({category, subCategory, rowKey, rowSubKey, columnKey, fields, data, columnCategories, percentageByGroup, selectedFormatOption, rowDisplayName, rowSubDisplayName}) => {
    const result = {};

    // first column (title column)
    result[clean(rowKey)] = category === undefined ?
        'Total':
        getDisplayValue({value: category, field: fields.find(f => f.displayName == rowDisplayName)}); 

    // secondary title column (if using subcategory)
    if (subCategory !== undefined) {
        result[clean(rowSubKey)] = getDisplayValue({value: subCategory, field: fields.find(f => f.displayName == rowSubDisplayName)});
    }

    // calculate the total number of records
    const recordTotal = getRecordTotal({data, category, rowKey, rowSubKey, category, subCategory});

    // populate all columns with values in
    columnCategories.forEach(column => {
        const myRecords = getRecords({data, columnKey, column, rowKey, category, rowSubKey, subCategory})
        const count = myRecords.length.toString();
        const percentage = getPercentage({percentageByGroup, myRecords, recordTotal, data});

        result[clean(column)] = formatCellValue({count, percentage, selectedFormatOption});
    });

    return result;
};

/* RETURNS:
    * data: array of objects to make up Tabulator table data
    * columns: columns config object for Tabulator to present data
*/

export function getSummaryDataAndColumns ({
    studentData, // main data for app
    tags, // main tags object
    fields: myFields, // main fields object
    rowKey, //  field key for row disaggregation
    rowSubKey=null, // field key for secondary row disaggregation
    columnKey, //  field key for column disaggregation
    filters=[], /** array of filter objects in following format:
    *       field: field key (string)
    *       type: (optional) operation to compare values. Can be any of '=', '>', '<'. Defaults to '='. (string)
    *       value: value to match (string/number/bool depending on field data type) */
    selectedFormatOption, /** show table values as percentage, count, or both. Options are:
    *  - 'Show count'
    *  - 'Show percentage'
    *  - 'Show count and percentage' */
    showSumRow=false, // function will return a final row summing each column, OR (if showAsPercentage is true) showing the percentage of all students. (bool)
    percentageByGroup=false // calculate percentages by columns, not rows (bool)
}) {

    // Validate input

    let shouldAbort = false;

    [studentData, tags, myFields, rowKey, columnKey, selectedFormatOption].forEach(attribute => {
        if (attribute === undefined) {
            console.warn('Missing expected attribute:', attribute);
            shouldAbort = true;
        }
    });

    if (shouldAbort) return {data: [], columns: []};

    // Set up inputs

    const fields = addTagFields({fields: myFields, tags}),
        processedData = applyFilters({
            data: addTagValues({data: studentData, tags}), 
            filters
        });
    
    const columnField = fields.find(field => field.key == columnKey),
        rowDisplayName = getFieldDisplayName({key:rowKey, fields}),
        rowSubDisplayName = getFieldDisplayName({key:rowSubKey, fields}),
        columnDisplayName = getFieldDisplayName({key:columnKey, fields}),
        rowCategories = getUniqueSet({key: rowKey, fields, data: processedData}),
        columnCategories = getUniqueSet({key: columnKey, fields, data: processedData}),
        rowSubCategories = getUniqueSet({key: rowSubKey, fields, data: processedData});

    // create table data

    const getTableRow = ({category, subCategory} = {}) => createTableRow({
        category, 
        subCategory, 
        rowKey, 
        rowSubKey, 
        columnKey, 
        rowDisplayName, 
        rowSubDisplayName,
        fields, 
        columnCategories, 
        percentageByGroup, 
        selectedFormatOption, 
        data: processedData,
    });

    const data = rowCategories
        .map(category => rowSubCategories.map(subCategory => getTableRow({category, subCategory})))
        .concat(showSumRow ? getTableRow() : [])
        .flat();

    // create columns config

    const columns = [{ // first column
        title: rowDisplayName.toString(),
        field: clean(rowKey).toString()
    }].concat(rowSubKey ? [{ // second column
        title: rowSubDisplayName.toString(),
        field: clean(rowSubKey).toString()
    }]:[]).concat( // other columns
        columnCategories.map(category => ({
            title: getDisplayValue({value: category, field: fields.find(f => f.displayName == columnDisplayName)}),
            field: clean(category).toString()
        })).sort((a, b) => orderSet({a: a.title, b: b.title, dataMissingWarning, field: columnField}))
    );

    return {data, columns};
};


const createVictoryTableRow = ({field, data, columnKey, columnCategories, selectedFormatOption}) => {
    const result = {};

    // first column
    result.rowField = field.displayName;

    // other columns
    columnCategories.forEach(category => {
        const recordTotal = getRecordTotal({data, category, rowKey: columnKey});
        const myRecords = data
            .filter(record => getValueFromObject(record, columnKey) == category)
            .filter(record =>  field.victory(getValueFromObject(record, field.key)));
        
        const count = myRecords.length.toString();
        const percentage = getPercentage({percentageByGroup: false, myRecords, recordTotal, data})

        result[category] = formatCellValue({count, percentage, selectedFormatOption});
    });

    return result;
};

/*
    * RETURNS:
    * data: array of objects to make up Tabulator table data
    * columns: columns config object for Tabulator to present data
    * 
*/

export function getVictorySummaryDataAndColumns ({
    data: inputData, // main data for app
    fields, // main fields object
    rowFieldKeys, // array of field keys for rows (array)
    columnKey, // field key for disaggregation (string)
    selectedFormatOption /** show table values as percentage, count, or both. Options are:
        *  - 'Show count'
        *  - 'Show percentage'
        *  - 'Show count and percentage' */
}) {

    // Validate input

    let shouldAbort = false;

    [inputData, fields, rowFieldKeys, columnKey, selectedFormatOption].forEach(attribute => {
        if (attribute === undefined) {
            console.warn('Missing expected attribute:', attribute);
            shouldAbort = true;
        }
    });

    if (shouldAbort) return {data: [], columns: []};

    // Set up inputs

    const processedData = inputData.filter(record => ['4-Year College', '2-Year College'].includes(record.plans[0].value));
    const rowFields = rowFieldKeys.map(element => fields.find(field => field.key == element)),
        columnGroup = getFieldDisplayName({key: columnKey, fields}),
        columnCategories = getUniqueSet({key: columnKey, fields, data: processedData});
    
    const data = rowFields.map(field => createVictoryTableRow({
        field, data: processedData, columnKey, columnCategories, selectedFormatOption}
    ));    

    const columns = [{ // first column
        title: 'Measure',
        field: 'rowField'
    }].concat( // other columns
        columnCategories.map(category => ({
            title: getDisplayValue({value: category, field: fields.find(f => f.displayName == columnGroup)}),
            field: category.toString()
        }))
    );

    return {data, columns};
};

const getFilter = ({key, value, fields}) => {
    const displayName = getFieldDisplayName({key, fields});

    if (!displayName) return;

    return {
        displayName,
        field: key,
        type: 'customEquals',
        displayType: ':',
        value: unclean(value)
    };
};

/**
 * RETURNS: Custom prop object
    * name: 'Custom'
    * fields: fields to show on tracker table
    * filters: filters to implement on tracker table
    * 
*/

export function getRedirectCustomPropFromChart ({
    filters: inputFilters, // array of objects {key: field key, value: value to filter by}
    fields: inputFields, // main fields object
    redirectDefaultFields // field keys to add in as standard when creating tracker view
}) {
    return { 
        name: 'Custom',
        fields: inputFilters
            .map(element => element.key)
            .concat(redirectDefaultFields)
            .map(key => getFieldDisplayName({key, fields: inputFields})), 
        filters: inputFilters.map(element => getFilter({
            key: element.key, 
            value: element.value, 
            fields: inputFields
        }))
    };
};

export function getRedirectCustomProp ({
    rowKey, // field key for row disaggregation (string)
    rowSubKey=null, // (optional) field key for secondary row disaggregation (string)
    columnKey, //  field key for column disaggregation (string)
    fields: inputFields, // main fields object
    rowValue,
    rowSubValue,
    columnValue,
    redirectDefaultFields // field keys to add in as standard when creating tracker view
}) {

    const rowField = inputFields.find(field => field.key == rowKey),
        colField = inputFields.find(field => field.key == columnKey),
        rowSubField = rowSubKey == null ? null : inputFields.find(field => field.key == rowSubKey);

    let shouldAbort = false;
    [rowField, colField, rowSubField].forEach(element => {
        if (element === undefined) {
            console.warn('field is undefined');
            shouldAbort = true;
        }
    });
    if (shouldAbort) return;

    if (columnValue == 'noData') columnValue = null;

    const rowGroupValue = getGroupValue({field: rowField, value: rowValue}),
        rowSubGroupValue = rowSubKey == null ? null: getGroupValue({field: rowSubField, value: rowSubValue}),
        columnGroupValue = processTrueFalse(columnValue);

    const filters = []
        .concat(
            rowGroupValue === 'Total' ? 
                [] : 
                getFilter({
                    key: rowField.key, 
                    value: rowGroupValue, 
                    fields: inputFields
                }))
        .concat(
            getFilter({
                key: colField.key, 
                value: columnGroupValue, 
                fields: inputFields
            }))
        .concat(
            rowSubKey === null ? 
                [] : 
                getFilter({
                    key: rowSubField.key, 
                    value: rowSubGroupValue, 
                    fields: inputFields
                }));

    const fields = [rowKey, columnKey]
        .concat(rowSubKey === null ? [] : rowSubKey)
        .concat(redirectDefaultFields)
        .map(key => getFieldDisplayName({key, fields: inputFields}));

    return { 
         name: 'Custom',
         fields: [...new Set(fields)],
         filters
    };
};
