<template>
    <div class="table-hider">
        <div class="sk-chase">
            <div 
                v-for="(item, index) in [1, 2, 3, 4, 5, 6]" :key="index"
                class="sk-chase-dot"
            ></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

// Spinner from https://tobiasahlin.com/spinkit/

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  top: 200px;
  left: 200px;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; 
  animation: sk-chase-dot 2.0s infinite ease-in-out both; 
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--color-contrast-full);
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
}

@for $i from 1 through 6 {
  $x: - 1.2s + ($i * 0.1s);
  .sk-chase-dot:nth-child(#{$i}) { animation-delay: $x; }
  .sk-chase-dot:nth-child(#{$i}):before { animation-delay: $x; }
}

@keyframes sk-chase {
  100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
}
</style>