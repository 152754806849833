<template>
<div v-if="courses" class="courses-area">
    <div class="courses-heading">Courses</div>
    <table class="course-table">
        <tr>
            <th class="courseCol">Course title</th>
            <th class="teacherCol">Teacher</th>
            <th class="gradeCol" v-for="(markingPeriod, i) in markingPeriods" :key="i">
                {{ markingPeriod }}
            </th>
        </tr>
        <tr v-for="(k, i) in courseKeys" :key="i">
            <td class="courseCol">{{ courses[k].courseTitle }}</td>
            <td class="teacherCol">{{ courses[k].teacher.lastName }}</td>
            <th v-for="(markingPeriod, j) in markingPeriods" 
                :key="j"
                :class="getClass(courses[k].letterGrade[markingPeriod])"
                class="gradeCol"
            >
                {{ courses[k].letterGrade[markingPeriod] }}
            </th>
        </tr>
    </table>
</div>
</template>

<script>
export default {
    props: {
        courses: Object
    },
    data () {
        return {
            markingPeriods: ['Q1', 'Q2', 'S1', 'Q3', 'Q4', 'S2', 'Y1']
        }
    },
    computed: {
        courseKeys: function () {
            return this.courses ? Object.keys(this.courses) : [];
        }
    },
    methods: {
        getClass(letterGrade) {
            if (!letterGrade) return '';
            return 'grade-class-' + letterGrade.slice(0,1);
        }
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors.scss';

.courses-area {
    .grade-class-A {
        background-color: var(--color-grade-a);
        color: var(--color-bg);
    }
    .grade-class-B {
        background-color: var(--color-grade-b);
        color: var(--color-bg);
    }
    .grade-class-C {
        background-color: var(--color-grade-c);
        color: var(--color-bg);
    }
    .grade-class-D {
        background-color: var(--color-grade-d);
        color: var(--color-bg);
    }
    .grade-class-F {
        background-color: var(--color-grade-f);
        color: var(--color-bg);
    }
    .grade-class-P {
        background-color: var(--color-grade-p);
        color: var(--color-bg);
    }
}
</style>