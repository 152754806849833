<template>
    <div id="protocol-slides">
        <div :class="getMainAreaWidthClass">
            <div class="slide-container">
                <table class="protocol-slide-area">
                    <tr class="slide-main-row">
                        <td class="previous-slide">
                            <span class="material-icons-outlined" @click="incrementSlide(-1)" :class="currentIndex==0 ? 'disabled-arrow' : ''">
                                arrow_back_ios
                            </span>
                        </td>
                        <td class="protocol-slide">
                            <h1 class="slide-header">{{ slides[currentIndex].title }}</h1>
                            <div class="slide-body">
                                <div 
                                    v-for="(chart, index) in slides[currentIndex].charts" :key="`${index}_${currentIndex}`"
                                    class="slide-chart-container" 
                                    :style="`max-width: ${getMaxWidth(slides[currentIndex])}%`"
                                >
                                    <MyChart
                                        :chartConfig="addInteraction(chart.chartConfig)"
                                        :chartData="store.state.studentData"
                                    />
                                </div>
                                <ul class="questions">
                                    <li v-for="(question, index) in slides[currentIndex].questions" :key="index">
                                        {{ question }}
                                    </li>
                                </ul>
                            </div>
                        </td>
                        <td class="next-slide">
                            <span class="material-icons-outlined" @click="incrementSlide(1)" :class="currentIndex==slides.length-1 ? 'disabled-arrow' : ''">
                                arrow_forward_ios
                            </span>
                        </td>
                    </tr>
                    <tr class="slide-navigation">
                        <td></td>
                        <td class="hyphen-area">
                            <span class="hyphen" v-for="(slide, index) in slides" :key="index">
                                <span :class="index==currentIndex ? 'active-slide' : ''" @click="currentIndex=index">
                                    —&nbsp;
                                </span>
                            </span>
                        </td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>    
</template>

<script>
import { getMainAreaWidthClass } from '@/functions/utils.js';
import Sidebar from '@/components/sidebar.vue';
import SidebarOption from '@/components/sidebarOption.vue';
import MyChart from '@/components/charts.vue';
import { getRedirectCustomPropFromChart } from '@/functions/summary.js';

export default {
    components: { Sidebar, SidebarOption, MyChart },
    data() {
        return {
            currentIndex: 0,
            slides: this.config.slides || []
        }
    },
    methods: {
        incrementSlide(int) {
            this.currentIndex = Math.max(Math.min(this.currentIndex + int, this.slides.length - 1), 0);
        },
        getMaxWidth(slide) {
            return slide.charts[0].chartConfig.type === 'pie' ? 40 : 70;
        },
        chartClickHandler({filters}) {
            this.store.setCustomProp(getRedirectCustomPropFromChart({
                filters,
                redirectDefaultFields: this.config.analyticsConfig.redirectDefaultFields,
                fields: this.config.fields,
            }));

            this.$router.push('/tracker');
        },
        addInteraction(chartConfig) {
            chartConfig.onClick = this.chartClickHandler;
            return chartConfig;
        }
    },
    computed: {
        getMainAreaWidthClass: function () {
            // returns the CSS class for the main area
            return getMainAreaWidthClass(
                false, // this.store.state.ui.sidebarVisible, 
                this.store.state.ui.chatVisible
            );
        },
    },
    activated() {
        // this.fb.events.add({ action: 'view', target: 'slides' });
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/mystyles.scss';

    .protocol-slide-area {
        width: 100%;
    }

    .slide-main-row {
        height: calc(100vh - 150px);
    }

    .slide-chart-container {
        margin:auto;
    }

    .slide-navigation {
        text-align: center;
    }

    .previous-slide, .next-slide {
        text-align: center;
        vertical-align: middle;
        width: 5%;
        span {
            font-size: 45px;
            &:hover {
                cursor: pointer;
                color: var(--color-primary-light);
            }
        }
    }
    .disabled-arrow {
        color: var(--color-contrast-low);
        &:hover {
            cursor: not-allowed !important;
            color: var(--color-contrast-low) !important;

        }
    }
    .protocol-slide {
        text-align: center;
        vertical-align: top;
        border-radius: 10px;
        box-shadow: 0px 0px 15px 1px rgba(140, 140, 140, 0.2);
        width: 90%;
        img {
            width: 300px;
        }
    }
    .hyphen-area {
        font-size: 90px;
        position: relative;
        top: -20px;
        color: var(--color-contrast-low);
        .hyphen {
            &:hover {
                cursor: pointer;
                color: var(--color-primary-light);
            }
        }
        .active-slide {
            color: var(--color-primary);
            &:hover {
                cursor: pointer;
                color: var(--color-primary-light);
            }
        }
    }
</style>