<template>
    <Modal data-cy="add-new-view-modal" ref="addView" title="Add new view" instructions="Enter name of custom view" @success="addView">
        <input v-model="modals.addView" placeholder="Type here">
    </Modal>
    <Modal data-cy="rename-view-modal" ref="renameView" title="Rename view" instructions="Enter name of custom view" @success="renameView">
        <input v-model="modals.renameView" placeholder="Type here">
    </Modal>
    <div class="views-component">
        <CustomSelect style="display: inline-block;" :options="views" displayField="name" v-model="selectedFocus" />
        <Popout>
            <div
                @click="this.$refs.addView.show()" 
                @mouseover="showTooltip('Save the current view so you can return to it later.')"
            >
                Add New View
            </div>
            <div 
                v-if="showingCustomView" 
                @click="updateView()" 
                @mouseover="showTooltip('Update the currently selected view to match the current table layout.')"
            >
                Update View
            </div>
            <div
                v-if="showingCustomView"
                @click="this.$refs.renameView.show()"
                @mouseover="showTooltip('Rename the current view.')"
            >
                Rename View
            </div>
            <div
                v-if="selectedFocus!==''"
                @click="setViewAsDefault()"
                @mouseover="showTooltip('Set the current view as default. It will show when you open the app.')"
            >
                Set View as Default
            </div>
            <div
                class="warning"
                v-if="showingCustomView"
                @click="deleteView()"
                @mouseover="showTooltip('Delete the current view from this dropdown menu.')">
                Delete View
            </div>
        </Popout>
    </div>
</template>

<script>
import Popout from '@/components/popout.vue';
import Modal from '@/components/modal.vue';
import CustomSelect from '@/components/customSelect.vue';

export default {
    props: {
        customViews: Array
    },
    data() {
        return {
            selectedFocus: '',
            modals: {
                addView: '',
                renameView: '',
            }
        }
    },
    components: {
        Popout, CustomSelect, Modal
    },
    emits: ['showTooltip', 'applyView', 'addView', 'updateView', 'renameView', 'notify', 'deleteView'],
    computed: {
        views: function () {
            // combines custom views and default views to create a list of all views
            return this.customViews.concat(
                this.config.trackerConfig.defaultViews.sort((a, b) => a.name > b.name ? 1 : -1)
            );
        },
        showingCustomView: function () {
            // decide if the current selected view is a custom view
            const myView = this.views.find(element => element.name == this.selectedFocus);
            if (!myView) return false;
            if (!myView.custom) return false;
            return true;
        },
    },
    watch: {
        selectedFocus: function () {
            this.$emit('applyView', {selectedFocus: this.selectedFocus, views: this.views});
        }
    },
    methods: {
        showTooltip(str) {
            this.$emit('showTooltip', str);
        },
        setSelectedFocus(str) {
            this.selectedFocus = str;
        },
        avoidDuplicate(str, views=this.views) { 
            // prevent saving view with duplicate name
            
            const duplicateExists = str => views.find(element => element.name == str) ? true : false;

            if (duplicateExists(str)) {
                let foundUnique = false, counter = 0, newStr = '';
                while (!foundUnique) {
                    counter++;
                    newStr = str + '-' + counter.toString();
                    foundUnique = !duplicateExists(newStr);
                }
                return newStr
            } else {
                return str;
            }
        },
        addView() { 
            // add user-defined view // called when closing 'addView' modal window

            let name = this.modals.addView || 'My Custom View';
            name = this.avoidDuplicate(name);

            this.$emit('addView', name);
            this.selectedFocus = name;
            this.saveViews();
        },
        renameView() {
            // rename user defined view // called when closing 'renameView' modal window

            let name = this.modals.renameView || 'My Custom View';
            name = this.avoidDuplicate(name);

            const oldName = this.selectedFocus;
            this.$emit('renameView', {oldName, name});
            this.selectedFocus = name;
            this.saveViews();
        },
        updateView() {
            // update user defined view to current table layout

            this.$emit('updateView', this.selectedFocus);
            this.saveViews();
        },
        setViewAsDefault() {
            const newValue = {
                PST: this.$user.preferences.defaultView.PST,
                G8T: this.$user.preferences.defaultView.G8T,
                GLT: this.$user.preferences.defaultView.GLT
            };

            newValue[this.store.state.currentApp] = this.selectedFocus;
            this.fb.user.updatePreference('defaultView', newValue);
            this.notify('Current view set as default');
        },
        notify(str) {
            this.$emit('notify', str);
        },
        deleteView() {
            // delete user defined view
            this.$emit('deleteView', this.selectedFocus);

            const currentView = this.customViews.find(element => element.name == this.selectedFocus);

            // reset view to default if one is set, or to config default
            this.selectedFocus = this.$user.preferences.defaultView[this.store.state.currentApp] == currentView.name ?
                this.config.trackerConfig.defaultView:
                this.$user.preferences.defaultView[this.store.state.currentApp] || this.config.trackerConfig.defaultView;

            // reset user default view if necessary
            if (this.$user.preferences.defaultView[this.store.state.currentApp] == currentView.name) {
                const newValue = {
                    PST: this.$user.preferences.defaultView.PST,
                    G8T: this.$user.preferences.defaultView.G8T,
                    GLT: this.$user.preferences.defaultView.GLT
                };
                newValue[this.store.state.currentApp] = this.config.trackerConfig.defaultView;
                this.fb.user.updatePreference('defaultView', newValue);
            }
            this.saveViews();
            this.notify('View deleted');
        },
        saveViews() {
            // serialize customviews object ready to save
            const thingToSave = JSON.stringify(this.customViews);

            const newValue = {
                PST: this.$user.preferences.customViews.PST,
                G8T: this.$user.preferences.customViews.G8T,
                GLT: this.$user.preferences.customViews.GLT
            };
            newValue[this.store.state.currentApp] = thingToSave;
            this.fb.user.updatePreference('customViews', newValue);
        }
    }
}
</script>

<style>

</style>