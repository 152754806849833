<template>
    <button class="plus-minus-button" :class="classes" :disabled="disabled" >{{ add ? '+' : '✖' }}</button>
</template>

<script>
export default {
    props: {
        add: Boolean,
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
        }
    },
    computed: {
        classes: function () {
            return this.disabled ?
                'disabled' :
                this.add ? 
                    'add' :
                    'subtract'
        }
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/colors.scss';

    .add {
        background-color: var(--color-success);
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0px 6.5px 1px;
        &:hover {
            background-color: var(--color-success-light);
        }
    }

    .disabled {
        background-color: var(--color-contrast-high);
        font-size: 1.8rem;
        font-weight: bold;
        padding: 0px 6.5px 1px;
        &:hover {
            cursor: not-allowed;
        }
    }

    .subtract {
        background-color: var(--color-warning);
        font-size: 1.4rem;
        padding: 2px 7px 4px;
        &:hover {
            background-color: var(--color-warning-light);
        }
    }

    .plus-minus-button {
        font-family: 'Lato', sans-serif;
        border-radius: 16px;
        border: none;
        color: var(--color-bg);
        margin: 7px 0;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        transition-duration: 0.3s;
        cursor: pointer;
    }
</style>