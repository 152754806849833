<template>
    <div class="details-app">
        <transition name="slide-fade">
            <Sidebar v-if="store.state.ui.sidebarVisible">
                <div data-cy="select-student-area" class="select-student-area">
                    <CustomButton v-if="selectedStudent" @click="downloadPDF" buttonText="Download PDF" />
                    <CustomSelect :options="studentNames" displayField="name" title="Select Student" v-model="selectedStudent" @selectedIndex="selectStudent" />
                </div>
                <div class="student-search-area">
                    <p>Search for student</p>
                    <input v-model="searchString" type="text" 
                        @keydown.enter="submitSearch"
                        @keydown.down="nextStudent"
                        @keydown.up="previousStudent"
                    >
                </div>
                <div class="list-of-students">
                    <div 
                        v-for="(item, index) in matchingStudents" :key="index"
                        :class="item.class" 
                        class="student-lookup-entry" 
                        @mouseover="dropdownIndex = index" @click="submitSearch"
                    >
                        {{ item.name }}
                    </div>
                </div>
            </Sidebar>
        </transition>
        <div :class="getMainAreaWidthClass" class="student-details-area">
            <div data-cy="student-details" v-if="selectedStudent">
                <h1>{{ store.state.studentData[studentIndex].firstName }} {{ store.state.studentData[studentIndex].lastName }}</h1>
                <div class="tag-area">
                    <span v-for="(tag, index) in store.state.tags" :key="index">
                        <span v-if="studentHas(tag)"
                            class="badge details-badge"
                            :style="'background-color: #' + tag.color + '; color:' + blackOrWhite(tag.color) + ';'"
                        >
                            {{ tag.title }}
                        </span>
                    </span>
                </div>
                <table v-for="(group, index) in config.fieldGroups" :key="index">
                    <tr>
                        <th>{{ group.name }}</th>
                        <th></th>
                    </tr>
                    <tr v-for="(field, i) in getFieldsToShow(group.fields)" :key="i">
                        <td>{{ field.name }}</td>
                        <td :class="getDisplayClass(store.state.studentData[studentIndex], field.name)">
                            {{ getDisplayValue(store.state.studentData[studentIndex], field.name) }}
                            <span v-if="isEditable(field)">
                                <!-- following line will be edited to implement caseloads -->
                                <span 
                                    v-if="$user.hasPower('updateAllStudents')" 
                                    class="material-icons edit-message-button" 
                                    @click="launchEdit(field)"
                                >
                                    edit
                                </span>
                            </span>
                        </td>
                    </tr>
                </table>
                <CoursesTable v-if="config.profileConfig.showCourses" :courses="store.state.studentData[studentIndex].courses" />
                <div class="activity-area" v-if="logs.length">
                    <h3>Activity Log</h3>
                    <table data-cy="activity-table" class="activity-table">
                        <colgroup>
                            <col class="date-col">
                            <col class="user-col">
                            <col class="update-col">
                        </colgroup>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Update</th>
                        </tr>
                        <tr v-for="(log, index) in logs" :key="index">
                            <td>{{ log.date }}</td>
                            <td>{{ log.author }}</td>
                            <td>{{ log.field }} → {{ log.value }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div v-else>
                <h1 data-cy="no-student-selected-header">No student selected</h1>
                <p>Please select a student.</p>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/sidebar.vue';
import SidebarOption from '@/components/sidebarOption.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import { getValueFromObject, getMainAreaWidthClass, blackOrWhite } from '@/functions/utils.js';
import { getDetailsPDF } from '@/functions/detailsPDF.js'
import CoursesTable from '@/components/coursesTable.vue';

// PDFMake library
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getDisplayValue } from '@/functions/utils.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
    name: 'Student Profile',
    data() {
        return {
            studentIndex: 0,
            selectedStudent: '',
            fields: this.config.fields.filter(element => element.type !== 'notes' && element.type !== 'tags'),
            searchString: '',
            dropdownIndex: 0,
            courses: null
        }
    },
    watch: {
        searchString: function () {
            if (this.dropdownIndex > this.matchingStudents.length - 1) this.dropdownIndex = 0;
        }
    },
    methods: {
        initialize () {
            this.studentIndex = 0;
            this.selectedStudent = '';
        },
        downloadPDF () {
            const docDefinition = getDetailsPDF({
                student: this.store.state.studentData[this.studentIndex],
                fieldGroups: this.config.fieldGroups,
                fields: this.fields,
                logs: this.logs
            });
            pdfMake.createPdf(docDefinition).download();
            // this.fb.events.add({ action: 'download', target: 'studentProfile' });
        },
        nextStudent () {
            this.dropdownIndex++;
            if (this.dropdownIndex > this.matchingStudents.length - 1) this.dropdownIndex = 0;
        },
        previousStudent () {
            this.dropdownIndex--;
            if (this.dropdownIndex < 0) this.dropdownIndex = this.matchingStudents.length - 1;
        },
        submitSearch () {
            const myStudent = this.matchingStudents.find(element => element.class == 'selected');
            if (!myStudent) return;
            this.selectedStudent = myStudent.name;
            this.selectStudent(myStudent.index);
            this.searchString = '';
        },
        selectStudent (i) {
            this.studentIndex = i;
            const student = this.store.state.studentData[this.studentIndex];
            this.ee.emit('openChat', {
                id: student.studentRISEId,
                name: [student.firstName, student.lastName].join(' ')
            });
        },
        getFieldsToShow (fieldList) {
            return fieldList.filter(field => {
                const myField = this.fields.find(element => element.key == field.key);
                if (!myField) return false;
                return !myField.hideInStudentProfile;
            });
        },
        getDisplayValue (record, fieldName) {
            const field = this.fields.find(element => element.displayName == fieldName);
            if (!field?.key) return;
            const value = getValueFromObject(record, field.key);
            return getDisplayValue({value, field});
        },
        getDisplayClass (record, fieldName) {
            // format record field as victory, no victory, or neither
            const myField = this.fields.find(element => element.displayName == fieldName);
            if (!myField?.key) return;
            let myValue = getValueFromObject(record, myField.key);
            if (myValue === null) return 'entry no-data';
            if (myField.victory) {
                return myField.victory(myValue) ? 'entry victory' : 'entry no-victory'
            }
            return 'entry';
        },
        isEditable (field) {
            // decide if field can be edited
            const myField = this.fields.find(element => element.displayName == field.name);
            if (!myField) return false;
            if (!myField.readOnly) return true;
            return false;
        },
        launchEdit (field) {
            // after user clicks on edit pencil
            const student = this.store.state.studentData[this.studentIndex];
            const myField = this.fields.find(element => element.displayName == field.name);
            if (!myField) return false;

            const key = myField.key;
            if (!key) return;
            
            this.ee.emit('editField', {
                key: key,
                currentValue: getValueFromObject(student, key),
                id: student.studentRISEId,
                studentName: this.selectedStudent
            });
        },
        studentHas(tag) {
            const student = this.store.state.studentData[this.studentIndex];
            // returns true if student has the tag
            return student.tags?.[0]?.value ? // is there a log entry?
                student.tags[0].value.includes(tag.docID) ? // does that tag exist in the array?
                    true:
                    false:
                false;
        },
        blackOrWhite(color) {
            return blackOrWhite(color);
        }
    },
    components: {
        SidebarOption, Sidebar, CustomSelect, CustomButton, CoursesTable
    },
    computed: {
        matchingStudents: function () {
            if (!this.searchString) return [];
            return this.studentNames.filter(name => 
                    name.name.toLowerCase().includes(this.searchString.toLowerCase())
                ).slice(0,20)
                .map((element, i) => ({
                    name: element.name,
                    class: i == this.dropdownIndex ? 'selected' : '',
                    index: element.index
                }));
        },
        studentNames: function () {
            return this.store.state.studentData.map((student, i) => ({
                name: [].concat(student.lastName, student.firstName).join(', '),
                index: i
            }));
        },
        getMainAreaWidthClass: function () {
            return getMainAreaWidthClass(this.store.state.ui.sidebarVisible, this.store.state.ui.chatVisible);
        },
        logs: function () {
            // return activity log for student
            if (!this.selectedStudent) return [];

            const student = this.store.state.studentData[this.studentIndex];

            let result = [];
            this.fields.forEach(field => {
                const key = field.key;
                if (!key) return;

                const fieldContent = (() => {
                    if (!key.includes('.')) return student[key];
                    const keypath = key.split('.');
                    return student[keypath[0]][keypath[1]];
                })();
                
                if (!fieldContent) return;
                if (!Array.isArray(fieldContent)) return; // editable data is stored in an array of update logs.
                if (fieldContent.length <= 1) return; // if data has ever been edited
                
                // add update(s) to result
                fieldContent.forEach(entry => {
                    result.push({
                        date: new Date(entry.timestamp).toDateString(),
                        timestamp: entry.timestamp,
                        author: entry.displayName || '',
                        field: field.displayName,
                        value: getDisplayValue({value: entry.value, field})
                    });
                });

                result.pop(); // remove original field setting. We only want the updates
            });

            // sort all updates by creation time
            return result.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
        }
    },
    mounted() {
        this.ee.on('clearLocalState', () => {
            this.initialize();
        });
        this.ee.on('gradeLevelChange', newLevel => {
            this.initialize();
        });
        this.ee.on('launchApp', () => {
            // if there is no details page, ignore
            if (!this.config.applets.includes('profile')) return;

            this.fields = this.config.fields;//.filter(element => element.type !== 'notes' && element.type !== 'tags' && element.type !== 'courses');
        });

    },
    activated() {
        // this.fb.events.add({ action: 'view', target: 'profile' });

        // when navigating to details page from tracker, requesting specific student
        if (this.store.state.customProp) {
            const student = this.store.state.studentData.find(student => student.studentRISEId == this.store.state.customProp.id);
            this.studentIndex = this.store.state.studentData.indexOf(student);
            this.selectedStudent = this.store.state.customProp.name;
            this.store.setCustomProp(null);
        }
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors';

.details-app {
    .details-badge {
        margin-right: 5px;
    }

    .tag-area {
        margin: 12px 0 12px;
    }

    .student-details-area {
        overflow: auto;
        height: calc(100vh - 50px);
    }

    .entry {
        font-weight: bold;
    }

    .victory {
        color: var(--color-success);
    }

    .no-victory {
        color: var(--color-warning);
    }

    .no-data {
        color: var(--color-contrast-medium);
    }

    .activity-table {
        table-layout: fixed;
    }

    td, th {
        width: 250px;
        text-align: left;
    }

    h3 {
        font-size: 2rem;
        padding: 20px 0px 0px;
    }

    th {        
        font-size: 2rem;
        padding: 5px 0px;
    }

    .date-col {
        width: 100px;
    }

    .user-col {
        width: 100px;
    }

    .update-col {
        width: 600px;
    }

    .courses-heading {
        font-weight: bold;
        font-size: 2rem;
        padding: 20px 0px 0px;
    }

    .course-table {
        margin-top: 2rem;
        .gradeCol {
            text-align: center;
            width: 5%;
        }
    }
}

.edit-message-button {
    font-size: 1.5rem;
    position: relative;
    top: 1px;
    color: var(--color-contrast-medium);
    &:hover {
        color: var(--color-primary);
        cursor: pointer;
    }
}

.student-search-area {
    margin-left:20px; 
    margin-top:20px; 
    margin-bottom:20px;
    input {
        width: 80%;
        height: 30px;
        margin-top: 5px;
    }
}

.student-lookup-entry {
    margin-left: 20px;
    padding: 4px;
    background-color: var(--color-bg);
    width: 240px;
}

.selected {
    background-color: var(--color-primary) !important;
    color: var(--color-bg);
    cursor: pointer;
}

.select-student-area {
    margin-left:20px;
    margin-top:20px;
}


</style>