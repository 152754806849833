/* eslint no-unused-vars: "off" */

<template>
<div>
    <div :class="tableClass">
        <div ref="table"></div>
    </div>
</div>
</template>

<script>

import Tabulator from 'tabulator-tables';

// custom filter function that matches both log fields and normal fields

Tabulator.prototype.extendModule('filter', 'filters', {
    'customEquals': function (filterVal, rowVal) {
        if (Array.isArray(rowVal)) {
            if (!rowVal.length) return false;
            return rowVal[0].value == filterVal ? true : false;
        } else {
            return rowVal == filterVal ? true : false;
        }
    }
});

export default {
    name: 'MyTable',
    props: {
        tableData: Array,
        columns: Array,
        customConfig: Object,
        showAsInlineTable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tabulator: null,
            config: {},
            
        }
    },
    methods: {
        getColumnLayout() {
            return this.tabulator.getColumnLayout().map(e => ({field: e.field}));
        },
        setColumnLayout(layout) {
            if (!layout) return;
            this.tabulator.setColumnLayout(layout);
        },
        render(renderConfig) {
            this.columns.forEach(column => {
                if (!column.field) return;
                if (renderConfig.visibleColumns.includes(column.title)) {
                    this.tabulator.showColumn(column.field);
                } else {
                    if (column.field !== '_notes') {
                        this.tabulator.hideColumn(column.field);
                    }
                }
            });
            const myFilters = renderConfig.filters.filter(f => f !== undefined);
            this.tabulator.setFilter(myFilters);
            this.tabulator.redraw();
        },
        scrollToColumn(key) {
            this.tabulator.scrollToColumn(key);
        },
        hideColumn(key) {
            this.tabulator.hideColumn(key);
        },
        showColumn(key) {
            this.tabulator.showColumn(key);
        },
        setHeaderFilterValue(lookup, val) {
            this.tabulator.setHeaderFilterValue(lookup, val)
        },
        setData(obj) {
            this.tabulator.setData(obj);
        },
        replaceData(obj) {
            this.tabulator.replaceData(obj);
        },        
        setDataAndColumns(obj) {
            this.tabulator.setColumns(obj.columns);
            this.tabulator.setData(obj.data);
        },
        download() {
            // this requires sheetjs library, included in head at index.html. Including with Vue/webpack did not work for me
            this.tabulator.download('xlsx', 'appData.xlsx', {sheetName:'App Download Data'});
        },
        downloadMultiple(sheets) {
            this.tabulator.download('xlsx', 'allTables.xlsx', {sheets: sheets});
        },
        launch({
            columns = this.columns,
        } = {}) {
            this.config = {
                height: this.customConfig.height || '100%',
                layout: this.customConfig.layout || 'fitColumns',
                reactiveData: true,
                data: this.tableData, 
                columns: columns,
                movableColumns: true,
                cellDblClick: this.customConfig.cellDblClick || null,
                rowFormatter: row => {
                    if (this.store.state.ui.rowWidth) {
                        const myClasses = [
                            { 
                                displayName: 'Default',
                                className: 'custom-row-default'
                            },
                            { 
                                displayName: 'Wide',
                                className: 'custom-row-wide'
                            },
                            {
                                displayName: 'Extra Wide',
                                className: 'custom-row-extra-wide'
                            }
                        ];
                        
                        const classToAdd = myClasses.find(element => element.displayName == this.store.state.ui.rowWidth);

                        if (classToAdd) {
                            row.getElement().classList.add(classToAdd.className);

                            const classesToRemove = myClasses
                                .filter(element => element.displayName !== this.store.state.ui.rowWidth)
                                .map(element => element.className);
                            
                            row.getElement().classList.remove(...classesToRemove);
                        }
                    }
                }
            };

            this.tabulator = new Tabulator(this.$refs.table, this.config);            
        },
        reload({columns}) {
            this.launch({columns});
        }
    },
    computed: {
        tableClass: function () {
            return this.showAsInlineTable ? 'table-container-inline' : 'table-container';
        }
    },
    mounted() {
        this.launch();
    }
}
</script>

<style lang="scss">
    @import '../assets/styles/tabulator_custom.scss';
    @import '../assets/styles/tableStyles.scss';
</style>