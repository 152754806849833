/* eslint no-unused-vars: "off" */

<template>
    <div class="tracker-app">
        <Notification ref="message" :msg="messageText"/>
        <transition name="slide-fade">
            <Sidebar v-if="store.state.ui.sidebarVisible">
                <SidebarOption 
                    data-cy="views-sidebar-option" 
                    title="Views" 
                    description="This section shows saved layouts of the tracker table. You can save your own views to return to later."
                >
                    <Views ref="views" :customViews="customViews" @showTooltip="showTooltip" @applyView="applyView({views: getViews()})"
                        @addView="addView" @renameView="renameView" @updateView="updateView" @notify="notify" @deleteView="deleteView"
                    />
                </SidebarOption>
                <SidebarOption data-cy="filters-sidebar-option" title="Filters" :visibleOnStart="true" description="This section filters which students are shown in the tracker table.">
                    <Filters ref="filters" :matrixConfig="matrixConfig" @filtersChanged="setFilters" @toggleMatrix="toggleMatrix" />
                </SidebarOption>
                <SidebarOption data-cy="columns-sidebar-option" title="Columns" :visibleOnStart="false" description="This section lets you choose which columns are visible in the tracker table">
                    <div id="columns-select-area">
                        <div class="column-category" v-for="(group, i) in fieldGroups" :key="i">
                            <h3>{{ group.name }}</h3>
                            <div v-for="(item, index) in group.fields" :key="index">
                                <input type="checkbox" :id="item.name" v-model="item.checked">
                                <label :for="item.name">{{ item.name }}</label>
                            </div>
                        </div>
                    </div>
                </SidebarOption>
                <CustomButton style="margin-left:20px;" @click="download" buttonText="Download" @mouseover="showTooltip('Download the current view as .xlsx file.')"/>
            </Sidebar>
        </transition>
        <div :class="getMainAreaWidthClass">
            <div v-if="matrixConfig">
                <Matrix v-show="showMatrix" :matrixConfig="matrixConfig" @add-matrix-filter="addMatrixFilter"/>
            </div>
            <LoadingWidget v-if="loading"/>
            <div :class="courses ? 'coursesTable' : 'hidden'" id="coursesTable">
                <CoursesTable :courses="courses" />
            </div>

            <MyTable v-show="!showMatrix" :tableData="store.state.studentData" :columns="trackerColumns" :customConfig="trackerTableConfig" ref="trackerTable"/>
        </div>
    </div>
</template>

<script>
import MyTable from '@/components/table.vue';
import Sidebar from '@/components/sidebar.vue';
import Views from '@/components/views.vue';
import Matrix from '@/components/matrix.vue';
import CoursesTable from '@/components/coursesTable.vue';
import Filters from '@/components/filters.vue';
import Notification from '@/components/notification.vue';
import SidebarOption from '@/components/sidebarOption.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';
import PlusMinusButton from '@/components/plusMinusButton.vue';
import LoadingWidget from '@/components/loadingWidget.vue';
import { getMainAreaWidthClass } from '@/functions/utils.js';
import { formatCell } from '@/functions/formatters.js';
import { getMatrixCells, getMatrixFilters, getMatrixChart } from '@/functions/matrix.js';

export default {
    name: 'Tracker',
    data() {
        return {
            messageText: '',
            selectedFocus: '',
            filters: [],
            customViews: [], // loaded from user preferences when mounted
            trackerTableConfig: {
                cellDblClick: (e, cell) => this.cellDblClick(e, cell),
            },
            fieldGroups: this.config.fieldGroups,
            trackerColumns: this.config.fields.map(field => this.getTrackerColumns(field)),
            loading: false,
            showMatrix: false,
            matrixConfig: this.config.trackerConfig.matrixConfig,
            courses: null
        };
    },
    watch: {
        fieldGroups: {
            deep: true,
            handler() {
                if (!this.loading) {
                    this.renderTrackerTable();
                }
            }
        },
        filters: function () {
            if (!this.loading) {
                this.renderTrackerTable();
            }
        },
    },
    methods: {
        // Event listeners

        setEventListeners() {
            [
                { name: 'launchApp',            fn: () => this.launch() },
                { name: 'clearLocalState',      fn: () => this.clearLocalState() },
                { name: 'studentDataReceived',  fn: () => this.manualUpdateTracker() },
                { name: 'noteDataReceived',     fn: () => this.manualUpdateTracker() },
                { name: 'tagDataReceived',      fn: () => this.manualUpdateTracker() },
                { name: 'colorThemeChanged',    fn: () => this.manualUpdateTracker() },
                { name: 'rowWidthChanged',      fn: () => this.manualUpdateTracker() },
            ].forEach(event => {
                this.ee.on(event.name, event.fn);
            });
        },

        // Display

        notify(msg) {
            this.messageText = msg;
            this.$refs.message.show();
        },
        manualUpdateTracker() {
            if (!this.$refs.trackerTable) return;
            this.$refs.trackerTable.replaceData(this.store.state.studentData);
        },
        renderTrackerTable() {
            // update tabulator table 
            // when filters are changed, columns are selected, and views are applied
            if (!this.$refs.trackerTable) return;
            this.$refs.trackerTable.render({
                visibleColumns: this.visibleColumns,
                filters: this.filters,
            });
        },
        showDetails(cell) {
            // navigate to student profile page and open chat when user double clicks on student name
            if (!this.store.state.routes.find(element => element.name == 'Student Profile')) return;

            const cellData = cell._cell.row.data;

            const obj = {
                id: cellData.studentRISEId,
                name: [cellData.lastName, cellData.firstName].join(', ')
            };

            this.store.setCustomProp(obj);
            this.ee.emit('openChat', obj);
            this.$router.push('/profile');
        },
        applyViewPreferences(preferences) {
            // set default view and custom saved views
            const saved = preferences.customViews[this.store.state.currentApp];
            this.customViews = saved ? JSON.parse(saved) : [];
            const myFocus = preferences.defaultView[this.store.state.currentApp] || null;
            this.$refs.views.setSelectedFocus(myFocus);
        },

        // Matrix methods

        calculateMatrixCellsAndChart() {
            this.matrixConfig.cells = getMatrixCells({
                config: this.matrixConfig,
                data: this.store.state.studentData,
                fields: this.config.fields
            });
            this.matrixConfig.chart = getMatrixChart({
                config: this.matrixConfig,
            });
        },
        addMatrixFilter({i, j}) {
            const myMatrixFilters = getMatrixFilters({
                config: this.matrixConfig,
                fields: this.config.fields,
                i, j
            });
            
            this.addFilter({filterArr: myMatrixFilters});
            this.toggleMatrix();
        },
        toggleMatrix() {
            if (!this.showMatrix) {
                this.calculateMatrixCellsAndChart();
            }
            this.showMatrix = !this.showMatrix;
        },

        // Tracker Table methods

        getTrackerColumns(field) {
            // maps fields object to Tabulator config object
            return {
                title: field.displayName, 
                field: field.key, 
                mutator: field.mutator,
                formatter: cell => this.formatCell({cell, field}),
                headerFilter: field.headerFilter,
                headerFilterParams: field.headerFilterParams,
                headerFilterFunc: field.type == 'tags' ? 
                    (headerValue, rowValue, rowData, filterParams) => this.tagHeaderFilterFunc(headerValue, rowValue): 
                    field.headerFilterFunc,
                headerSort: field.headerSort,
                frozen: field.frozen,
                editor: field.editor,
                editorParams: field.editorParams,
                hozAlign: field.hozAlign || 'center',
                headerHozAlign: field.hozAlign || 'center',
                width: field.width || 120,
                sorter: field.sorter || null,
                titleFormatter: cell => this.titleFormatter(cell, field),
                cellClick: (e, cell) => this.cellClick(e, cell, field),
                cellMouseEnter: (e, cell) => this.cellMouseEnter(e, cell, field),
                cellMouseLeave: (e, cell) => this.cellMouseLeave(e, cell, field),
                accessorDownload: (value, data, type, params, column) => this.accessorDownload(value, field)
            };
        },
        accessorDownload(value, field) {
            if (field.type == 'tags') return this.getTagList(value);
            if (value == null) return '';
            if (!Array.isArray(value)) return value;
            if (value[0]?.value === undefined) return value;
            return value[0].value;
        },
        titleFormatter(cell, field) {
            // set tooltip when user hovers over. In settimeout because this function must wait until the table is fully built to access parentNode.parentNode
            setTimeout(() => {
                const headerEl = cell.getElement()?.parentNode?.parentNode;
                if (!headerEl) return;
                const description = field.userDescription || '';
                headerEl.addEventListener("mouseover", e => this.showTooltip(description));
            }, 1000);
            return cell.getValue();
        },
        cellClick(e, cell, field) {
            // user clicks on a cell

            const cellData = cell._cell.row.data;

            // open the chat when user clicks on the notes icon
            if (field.type == 'notes' && this.$user.hasPower('viewChat')) {
                this.ee.emit('openChat', {
                    id: cellData.studentRISEId.toString(),
                    name: [cellData.firstName, cellData.lastName].join(' ')
                });
            }

            // open the tags when user clicks on the tags field
            if (field.type == 'tags' && this.$user.hasPower('assignTags')) {
                this.ee.emit('openTagManager', cellData.studentRISEId);
            }
        },
        cellDblClick(e, cell) {
            // user double-clicks on a cell
            if (!this.$user.hasPower('updateAllStudents')) { // this will be changed later to lookup caseloads
                return;
            }

            const myField = this.config.fields.find(field => field.key == cell._cell.column.field);
            if (!myField) return;

            if (myField.displayName == 'Student Name') {
                this.showDetails(cell);
                return;
            }
            if (myField.readOnly) {
                this.notify('This field cannot be edited.');
                return;
            }

            // launch edit modal
            this.ee.emit('editField', {
                key: cell._cell.column.field,
                currentValue: cell._cell.value[0].value,
                id: cell._cell.row.data.studentRISEId,
                studentName: cell._cell.row.data._studentName
            });
        },
        cellMouseEnter(e, cell, field) {
            if (field.key !== 'courses') {
                this.courses = null;
                return;
            }
            setTimeout(() => {
                this.courses = cell.getValue();
                const ct = document.getElementById('coursesTable');
                if (ct) {
                    const top = e.clientY - 150;
                    const left = e.clientX < 650 ? e.clientX + 100 : e.clientX - 650;
                    ct.style.left = `${ left }px`;
                    ct.style.top = `${ top }px`;
                }              
            }, 20);

        },
        cellMouseLeave(e, cell, field) {
            if (field.key !== 'courses') return;
            this.courses = null;
        },
        formatCell({ // set formatters for every cell in tracker, taking into account victory conditions
            cell, 
            field, 
            colors = this.store.state.ui.colors,
            notes = this.store.state.notes,
            tags = this.store.state.tags
        }) {
            return formatCell({
                cell, field, colors, notes, tags
            });
        },

        // Tags

        tagHeaderFilterFunc(headerValue, rowValue, rowData, filterParams) {
            return this.getTagList(rowValue)
                .toLowerCase()
                .includes(headerValue.toLowerCase());
        },
        getTagList(value) {
            if (!value[0]) return '';
            const findTag = element => this.store.state.tags.find(tag => tag.docID == element);

            return value[0].value.map(element => {
                const t = findTag(element);
                return t?.title ? t.title : '';
            }).join(' ');
        },
        download() {
            this.$refs.trackerTable.hideColumn('_notes');
            this.$refs.trackerTable.download();
            this.$refs.trackerTable.showColumn('_notes');

            // this.fb.events.add({ action: 'download', target: 'studentData' });
        },

        // Views

        addView(name) { 
            this.customViews.unshift({
                name: name,
                custom: true,
                fields: this.visibleColumns,
                filters: this.filters,
                columnLayout: this.$refs.trackerTable.getColumnLayout(),
                app: this.store.state.currentApp
            });
        },
        renameView({oldName, name}) {
            const myView = this.customViews.find(element => element.name == oldName);
            if (!myView) {
                console.warn('view not found');
                return;
            }
            myView.name = name;
        },
        updateView(name) {
            const currentView = this.customViews.find(element => element.name == name);

            currentView.fields = this.visibleColumns;
            currentView.filters = this.filters;
            currentView.columnLayout = this.$refs.trackerTable.getColumnLayout();

            this.notify('View updated');
        },
        deleteView(name) {
            const currentView = this.customViews.find(element => element.name == name);
            this.customViews = this.customViews.filter(view => view !== currentView);
        },
        applyView({ // apply view to tracker table
            customView = null,
            views = this.getViews(),
            selectedFocus =  this.$refs.views?.selectedFocus || '',
            setVisibleColumns = this.setVisibleColumns,
            setColumnLayout = this.$refs.trackerTable.setColumnLayout,
            clearFilters = this.clearFilters,
            setFilters = this.$refs.filters?.setFilters || null,
            renderTrackerTable = this.renderTrackerTable
        } = {}) {

            const view = customView || views.find(element => element.name == selectedFocus);

            if (!view) return;
            if (customView) this.$refs.views.setSelectedFocus('');

            clearFilters();
            setVisibleColumns({fieldArray: view.fields});
            setColumnLayout(view.columnLayout);
            if (setFilters) setFilters(view.filters);
            renderTrackerTable();
        },

        // visible columns

        setVisibleColumns({
            fieldArray, 
            fieldGroups = this.fieldGroups
        }) {
            fieldGroups.forEach(group => {
                group.fields.forEach(field => {
                    field.checked = fieldArray.includes(field.name);
                });
            });
        },

        // Filters

        addFilter({filterArr}) {
            if (this.$refs.filters) {
                this.$refs.filters.addFilter({filterArr});
            }
        },
        setFilters(filters) {
            if (!filters) return;
            this.filters = filters;
        },
        clearFilters() {
            this.filters = [];
            if (this.$refs.filters) {
                this.$refs.filters.clearFilters();
            }
            this.clearHeaderFilters();
        },
        clearHeaderFilters() {
            if (!this.$refs.trackerTable) return;
            this.config.fields
                .filter(field => field.headerFilter !== undefined)
                .forEach(field => {
                    this.$refs.trackerTable.setHeaderFilterValue(field.key, '');
                });
        },

        // reset local state

        launch() {
            if (!this.$refs.trackerTable) return;
            if (!this.config.applets.includes('tracker')) return;

            this.loading = true;
            this.courses = null;
            this.matrixConfig = this.config.trackerConfig.matrixConfig;
            this.showMatrix = false;
            this.fieldGroups = this.config.fieldGroups;
            this.trackerColumns = this.config.fields.map(field => this.getTrackerColumns(field));
            this.$refs.trackerTable.setDataAndColumns({
                data: this.store.state.studentData,
                columns: this.trackerColumns
            });
            
            this.$refs.trackerTable.reload({columns: this.trackerColumns});

            setTimeout(() => {
                this.applyViewPreferences(this.$user.preferences);
                this.loading = false;
                this.applyView();
                this.renderTrackerTable();
            }, 1000);
        },
        clearLocalState() {
            if (!this.config.fields) return;
            this.clearFilters();
        },
        getViews() {
            return this.customViews.concat(
                this.config.trackerConfig.defaultViews.sort((a, b) => a.name > b.name ? 1 : -1)
            );
        }
    },
    computed: {
        getMainAreaWidthClass: function () {
            return getMainAreaWidthClass(this.store.state.ui.sidebarVisible, this.store.state.ui.chatVisible);
        },
        visibleColumns: function () { // return list of all visible columns
            return this.fieldGroups.map(group => group.fields
                    .filter(element => element.checked)
                    .map(element => element.name)
                ).flat();
        },
    },
    components: {
        MyTable, Sidebar, SidebarOption, CustomSelect, CustomButton,
        PlusMinusButton, Views, Notification, Matrix, Filters, 
        CoursesTable, LoadingWidget
    },
    mounted() {
        // apply default views and custom views
        if (this.$user.preferences) {
            this.applyViewPreferences(this.$user.preferences);
        }
        this.setEventListeners();
    },
    activated() {
        /* Programmatically set a custom view if one exists.
        * This is set by double-clicking a value on the summary page
        */

        this.$refs.trackerTable.scrollToColumn('_studentName'); // fixes bug of table not displaying correctly when returning to student tracker view
        if (this.store.state.customProp) {
            this.applyView({customView: this.store.state.customProp});
            this.store.setCustomProp(null);
        }
        // this.fb.events.add({ action: 'view', target: 'tracker' });
    },
}
</script>